@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/variables";
@import "../../../../../assets/scss/avocadoVariables";

.contentAddSection {
  display: flex;
  flex-direction: column;
  padding: map-get($spacing, md);
  gap: map-get($spacing, lg);
  border: 1px solid map-get($colors, layout-grey-30);
  border-radius: 0.5rem;
  width: 100%;

  &_title {
    font-size: map-get($sizeText, 16);
    line-height: map-get($sizeText, 18);
    font-weight: map-get($fonts, medium);
    color: map-get($colors, layout-grey-90);
  }

  &_group {
    justify-content: flex-start;
  }

  .field {
    width: 100%;
    max-width: 300px;
  }

  &_groupFields {
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    gap: map-get($spacing, md);
  }

  &_columFields {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    gap: map-get($spacing, lg);
  }

  &_sectionTitle {
    font-size: map-get($sizeText, 14);
    color: map-get($colors, layout-black);
    padding: map-get($spacing, xs) 0 0;
  }

  &_validations {
    list-style: none;

    .validationItem {
      @include text(0.875rem, message-alert-50);
      padding: 0.25rem 0 0;

      &:first-of-type {
        padding: 0;
      }

      &.validationItem__error {
        @include text(0.875rem, message-negative-50);
      }
    }
  }
}

.footer {
  padding: 32px;
  padding-left: 0px;
  &_link {
    margin-right: 20px;
  }
  &_btn {
    margin: 0 0 0 14px;
    padding: 0.625rem 24px;
    &:first-of-type {
      margin: 0;
    }
  }
}

@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/variables";

.days {
  &__label {
    @include text(0.875rem, layout-grey-90, regular);
    padding: 0.75rem 0 0.25rem 0;
  }
  &__options {
    margin: 0 0 0.75rem;
    display: flex;

    &:last-child {
      margin: 0;
    }
    &_item {
      margin: 0 0 0 1.5rem;
      &:first-of-type {
        margin: 0;
      }
      &_selectable {
        input {
          border: 1px solid map-get($colors, layout-grey-30);
        }
        width: auto;
      }
    }
  }
  .warning {
    width: 100%;
    &_text {
      @include text(0.75rem, layout-grey-90);
    }
  }
}

@import "../../../../assets/scss/avocadoVariables";
@import "../../../../assets/scss/mixin";

.timeRange {
  display: flex;
  flex-direction: column;

  &_fields {
    display: grid;
    grid-template-columns: 96px 10px 96px;
    gap: map-get($spacing, xs);

    .fieldSeparator {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .fieldControl {
      &_input {
        width: 100%;
        padding: map-get($spacing, xs) map-get($spacing, sm);
        min-height: 36px;
        border: 1px solid map-get($colors, layout-grey-30);
        border-radius: map-get($radius, md);
        font-size: map-get($sizeText, 14);

        &::placeholder {
          color: #c5c8cd;
        }
      }
    }
  }

  &_error {
    font-size: map-get($sizeText, 12);
    color: map-get($colors, message-negative-50);
    margin: map-get($spacing, xxs) 0 0;
  }
}

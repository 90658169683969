@import "../../../../../../assets/scss/mixin";
@import "../../../../../../assets/scss/variables";

.recommendations {
  @include text(14px, darwined-grey-70, bold);
  background-color: map-get($colors, layout-grey-5);
  box-shadow: 0 0 3px rgba(52, 58, 64, 0.2);
  margin-top: 1rem;
  min-width: 100%;

  &_header {
    @include text(14px, darwined-grey-70, bold);
    background-color: map-get($colors, layout-grey-5);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
  }

  &_loader {
    position: inherit !important;
    display: flex !important;
    &:after,
    &:before {
      top: 0.6rem !important;
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    max-height: 49.8rem;
    &_title {
      @include text(14px, layout-grey-90, bold);
    }
    &_sessions {
      &_ids {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 1rem 0;
        &_item {
          @include text(10px, layout-grey-90, bold);
          padding: 0 0.25rem;
          margin: 0.25rem 0.25rem;
          min-height: 1.8rem;
        }
      }
    }

    &_info {
      &_rows {
        display: flex;
        flex-direction: column;
        padding: 1rem 0;
        &_row {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          padding: 0.2rem 0;
          &_title {
            @include text(10px, layout-grey-90, bold);
            width: 50%;
          }
          &_data {
            @include text(10px, layout-grey-90);
            width: 50%;
          }
        }
      }
    }

    &_alternativesContainer {
      padding: 1rem 0;
      &_alternatives {
        .alternative {
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid map-get($colors, layout-grey-10);
          padding: 0.7rem 0;
          &:last-child {
            border-bottom: none;
          }

          &_row {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            padding: 0.2rem 0;
            &_title {
              @include text(10px, layout-grey-90, bold);
              width: 50%;
            }
            &_data {
              @include text(10px, layout-grey-90);
              width: 50%;
            }
          }
        }
      }
    }

    &_separator {
      border: 1px solid map-get($colors, layout-grey-10);
    }
  }

  &_buttonsSection {
    display: flex;
    @include text(10px, darwined-grey-70, bold);
    &_button {
      &:disabled {
        cursor: default !important;
      }
    }
  }
  &_discardButton {
    @include text(0.875rem, layout-grey-70, bold);
    background-color: map-get($colors, foris-white);
    border: 1px solid map-get($colors, layout-grey-70);
    margin-right: 1rem;
    &:hover {
      background-color: map-get($colors, layout-grey-10);
    }
    &:disabled {
      color: map-get($colors, foris-white);
      cursor: default;
      background-color: map-get($colors, layout-grey-30);
      border: none;
    }
  }
  &_unoutlined_button {
    @include text(0.875rem, layout-grey-70, bold);
    border: none !important;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: map-get($colors, layout-grey-70) !important;
    &_chevron {
      border: none !important;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:disabled {
      cursor: default;
    }
  }
  &_hiddenContent {
    padding: 0;
  }

  .color {
    &_0 {
      color: map-get($colorsPalette, palette10);
      border: 1px solid map-get($colorsPalette, palette10);
      &:hover,
      &_selected {
        color: map-get($colors, foris-white);
        border: 1px solid map-get($colorsPalette, palette10);
        background: map-get($colorsPalette, palette10);
      }
    }
    &_1 {
      color: map-get($colorsPalette, palette1);
      border: 1px solid map-get($colorsPalette, palette1);
      &:hover,
      &_selected {
        color: map-get($colors, foris-white);
        border: 1px solid map-get($colorsPalette, palette1);
        background: map-get($colorsPalette, palette1);
      }
    }
    &_2 {
      color: map-get($colorsPalette, palette2);
      border: 1px solid map-get($colorsPalette, palette2);
      &:hover,
      &_selected {
        color: map-get($colors, foris-white);
        border: 1px solid map-get($colorsPalette, palette2);
        background: map-get($colorsPalette, palette2);
      }
    }
    &_3 {
      color: map-get($colorsPalette, palette3);
      border: 1px solid map-get($colorsPalette, palette3);
      &:hover,
      &_selected {
        color: map-get($colors, foris-white);
        border: 1px solid map-get($colorsPalette, palette3);
        background: map-get($colorsPalette, palette3);
      }
    }
    &_4 {
      color: map-get($colorsPalette, palette4);
      border: 1px solid map-get($colorsPalette, palette4);
      &:hover,
      &_selected {
        color: map-get($colors, foris-white);
        border: 1px solid map-get($colorsPalette, palette4);
        background: map-get($colorsPalette, palette4);
      }
    }
    &_5 {
      color: map-get($colorsPalette, palette5);
      border: 1px solid map-get($colorsPalette, palette5);
      &:hover,
      &_selected {
        color: map-get($colors, foris-white);
        border: 1px solid map-get($colorsPalette, palette5);
        background: map-get($colorsPalette, palette5);
      }
    }
    &_6 {
      color: map-get($colorsPalette, palette6);
      border: 1px solid map-get($colorsPalette, palette6);
      &:hover,
      &_selected {
        color: map-get($colors, foris-white);
        border: 1px solid map-get($colorsPalette, palette6);
        background: map-get($colorsPalette, palette6);
      }
    }
    &_7 {
      color: map-get($colorsPalette, palette7);
      border: 1px solid map-get($colorsPalette, palette7);
      &:hover,
      &_selected {
        color: map-get($colors, foris-white);
        border: 1px solid map-get($colorsPalette, palette7);
        background: map-get($colorsPalette, palette7);
      }
    }
    &_8 {
      color: map-get($colorsPalette, palette8);
      border: 1px solid map-get($colorsPalette, palette8);
      &:hover,
      &_selected {
        color: map-get($colors, foris-white);
        border: 1px solid map-get($colorsPalette, palette8);
        background: map-get($colorsPalette, palette8);
      }
    }
    &_9 {
      color: map-get($colorsPalette, palette9);
      border: 1px solid map-get($colorsPalette, palette9);
      &:hover,
      &_selected {
        color: map-get($colors, foris-white);
        border: 1px solid map-get($colorsPalette, palette9);
        background: map-get($colorsPalette, palette9);
      }
    }
  }
}

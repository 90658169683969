@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/avocadoVariables";

.dropFilter {
  position: relative;
  padding: 0.688rem 0.75rem;
  border: 1px solid var(--color-neutral-30);
  border-right: none;
  background-color: var(--color-neutral-00);
  border-radius: map-get($radius, md) 0 0 map-get($radius, md);
  width: 116px;

  &_text {
    font-size: map-get($sizeText, 14);
    font-style: normal;
    font-weight: map-get($fonts, regular);
    line-height: map-get($sizeText, 18);
    color: var(--color-neutral-90);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: map-get($spacing, xs);
    user-select: none;

    .chevronIcon {
      transition: transform 0.25s ease-in-out;

      &.chevronIcon__open {
        transform: rotate(180deg);
      }
    }
  }
}

.dropFilterOptions {
  background-color: var(--color-neutral-00);
  position: absolute;
  top: calc(100% + 0.25rem);
  left: 0;
  right: 0;
  margin: 0;
  padding: map-get($spacing, xs);
  box-shadow: var(--shadow-elevate);
  list-style: none;
  z-index: map-get($zIndex, select);
  border-radius: map-get($radius, md);
  display: flex;
  flex-direction: column;
  gap: map-get($spacing, xxs);
  transform-origin: top;
  transform: scaleY(0);
  opacity: 0;
  animation: fadeInScale 0.3s ease-in-out forwards;

  &__close {
    animation: fadeOutScale 0.3s ease-in-out forwards;
  }

  &_item {
    padding: map-get($spacing, xs) map-get($spacing, sm);
    border-radius: 0.5rem;
    background-color: transparent;
    transition: background-color 0.25s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: var(--color-neutral-30-20);
    }

    .textSelect {
      font-size: map-get($sizeText, 14);
      font-style: normal;
      font-weight: map-get($fonts, regular);
      line-height: map-get($sizeText, 18);
      color: var(--color-neutral-70);
      user-select: none;
    }
  }
}

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes fadeOutScale {
  0% {
    opacity: 1;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform: scaleY(0);
  }
}

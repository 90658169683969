@import "../../../../../../assets//scss/mixin";
@import "../../../../../../assets/scss/variables";

.advanceSearch {
  padding: 2rem;
  .header {
    padding: 0 0 0.25rem;
    border-bottom: 1px solid map-get($colors, layout-grey-30);
    &_title {
      @include text(1.375rem, layout-grey-90);
      padding: 0 0 0.125rem;
    }
    &_subTitle {
      @include text(0.625rem, layout-grey-50);
    }
  }

  .content {
    padding: 2rem 0 0;
    .resume {
      padding: 0 0 0.25rem;
      border-bottom: 1px solid map-get($colors, layout-grey-50);
      &_count {
        @include text(0.875rem, layout-grey-90);
        .selected {
          font-weight: map-get($fonts, medium);
        }
      }
    }
  }

  .footer {
    padding: 2rem 0 0;
    &_btn {
      margin: 0 1rem 0 0;
      &:last-child {
        margin: 0;
      }
    }
  }
}

@import "../../../../../assets/scss/variables";
@import "../../../../../assets/scss/mixin";

.cntResults {
  padding: 1.75rem 1rem;
  &:first-child {
    margin: 0;
  }
  &_link {
    @include text(24px, layout-grey-90);
    @include flex(flex-start, center);
    padding: 0 0 0.75rem;
    cursor: pointer;
  }

  &_topPager {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    gap: map-get($spacing, sm);
    margin: 0;

    .cntResults_pager {
      margin: 0;
      order: 2;
      justify-content: center;
    }

    .orderWrapper {
      order: 1;
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 360px;
      gap: map-get($spacing, xs);
      margin: 0 auto;

      &_label {
        flex-shrink: 0;
        font-size: map-get($sizeText, 12);
        font-style: normal;
        font-weight: map-get($fonts, bold);
        color: var(--color-neutral-90);
      }
    }
  }

  &_pager {
    &:not(:first-of-type) {
      margin: 0.5rem 0 0;
    }
  }
}

@media (min-width: map-get($breakpoints, md )) {
  .cntResults {
    &_topPager {
      margin: 0 0 0.75rem;
      grid-template-columns: repeat(3, 1fr);

      .cntResults_pager {
        justify-content: flex-end;
        order: unset;
        grid-column-start: 2;
      }

      .orderWrapper {
        order: unset;
        grid-column-start: 3;
        padding: 0 0 0 map-get($spacing, lg);
        margin: 0 0 0 auto;
      }
    }

    &_pager {
      order: 1;

      &:not(:first-of-type) {
        margin: 0.75rem 0 0;
      }
    }
  }
}

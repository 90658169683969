@import "../../../assets/scss/mixin";
@import "../../../assets/scss/variables";

.cntEdit {
  padding: 1.5rem;
  flex: 1;
  &.cntEdit__detail {
    background: map-get($colors, layout-white);
  }
  &_title {
    @include text(0.75rem, layout-grey-50);
    padding: 0 0 0.75rem;
  }
  .headerList {
    display: grid;
    grid-template-columns: calc(100% - 400px) 100px 100px 100px 100px;
    padding: 0 0 0.5rem;
    margin: 0;
    list-style: none;
    &_item {
      @include text(0.75rem, layout-grey-90, bold);
      &:first-child {
        padding: 0 0 0 30px;
      }
    }
  }
  .infoList {
    @extend .headerList;
    grid-template-columns: 30px calc(100% - 430px) 100px 100px 100px 100px;
    padding: 0 0 0.75rem;
    &_item {
      @include text(0.875rem, layout-grey-90);
      text-transform: uppercase;
      .link {
        color: map-get($colors, darwined-blue-10);
        font-weight: map-get($fonts, medium);
        &_regular {
          font-weight: map-get($fonts, regular);
        }
        &:hover {
          color: map-get($colors, darwined-blue-30);
        }
        &:active {
          color: map-get($colors, darwined-blue-50);
        }
      }
      &.infoList_item__unassigment {
        @include text(0.875rem, layout-grey-30);
        text-transform: unset;
      }
      &.infoList_item__input {
        display: flex;
        position: relative;
        .inputItem {
          flex: 1;
          max-width: 50px;
          max-height: 24px;
          font-size: 0.875rem;
          padding: 0.25rem 0.8125rem 0.25rem 0.375rem;
          text-align: right;
        }
        .suffix {
          position: absolute;
          top: 3px;
          right: 54px;
        }
        .icon {
          padding: 0.25rem 0 0 0.25rem;
          color: map-get($colors, message-negative-30);
        }
      }
    }
  }
  .totalList {
    @extend .headerList;
    grid-template-columns: calc(100% - 400px) 100px 100px 100px;
    padding: 0.75rem 0 0;
    border-top: 1px solid map-get($colors, layout-grey-90);
    &_item {
      @include text(0.875rem, layout-grey-70, bold);
      flex: 1;
      max-width: 100px;

      .totalBlocks {
        width: 100%;
        text-align: left;
        padding: 0 0.25rem 0 0;
      }

      .totalInput {
        display: block;
        width: 50px;
        padding: 0 0.25rem 0 0.375rem;
        text-align: right;
      }
    }
  }
}

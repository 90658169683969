@import "../../../../assets/scss/avocadoVariables";

.packageLinkManagement {
  width: 100%;
  margin: 0 auto;
  padding: map-get($spacing, lg);
  display: flex;
  flex-direction: column;

  &_title,
  &_linkSelector {
    margin: 0 0 map-get($spacing, lg);
  }

  &_linkSelector {
    max-width: 300px;
  }

  .infoCard {
    margin: 0 auto map-get($spacing, lg) 0;
  }

  &_table {
    .cellWithIcon {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: map-get($spacing, xxs);

      .trashIcon {
        color: map-get($colors, layout-grey-50);
      }

      .addIcon {
        color: map-get($colors, darwined-blue-30);
      }
    }
  }

  .errorCard {
    margin-top: map-get($spacing, sm);
    box-shadow: map-get($shadow, md);
  }

  &_actions {
    margin: map-get($spacing, lg) 0 0;
    padding: map-get($spacing, xxs) 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: map-get($spacing, sm);
  }

  .packageLink {
    color: map-get($colors, darwined-blue-30);
  }
}

@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/variables";

.cntValidationReport {
  width: 100vw;
  min-height: calc(100vh - 132.5px - 4rem);
  padding: 2rem 2.5rem;
  background: map-get($colors, layout-white);
  &_title {
    @include text(18px, layout-grey-90, bold);
    margin: 0;
    padding: 0 0 0.75rem;
  }
  .cntCard {
    @include box(foris-white, 0.25rem, layout-grey-10);
    height: fit-content;
    margin: 0 0 0.5rem;
    padding: 1rem;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
    &:last-of-type {
      margin: 0;
    }
    &_title {
      @include text(1rem, layout-grey-90, medium);
      margin: 0;
      padding: 0 0 0.5rem;
    }
    .item {
      &.item__same {
        .item_label,
        .item_info {
          color: map-get($colors, layout-grey-30);
        }
      }
      &_label {
        @include text(10px, layout-grey-90, bold);
      }
      &_info {
        @include text(1rem, layout-grey-90);
      }
    }
  }
  .cntTable {
    margin: 3.25rem 0 0;
    &_title {
      @include text(1.125rem, layout-grey-90, bold);
      margin: 0;
      padding: 0 0 0.25rem;
    }
    &_text {
      @include text(1rem, layout-grey-90);
      margin: 0;
      padding: 0 0 1rem;
    }
    .incidenceValidation {
      &_session {
        @include text(12px, layout-grey-50);
        padding: 0 0 0 0.5rem;
      }
    }
    .numberWeek {
      @include text(14px, layout-grey-90, bold);
    }
  }
  .cntBtn {
    margin: 1.7rem 0 0;
    &_text {
      @extend .cntTable_text;
    }
    &_item {
      margin: 0 0 0 0.5rem;
      &:first-of-type {
        margin: 0;
      }
    }
  }
}

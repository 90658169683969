@import "../../../assets/scss/avocadoVariables";
@import "../../../assets/scss/mixin";

.link {
  color: map-get($colors, darwined-blue-50);

  &:hover {
    text-decoration: underline;
  }
}

@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/avocadoVariables";

.container {
  &_selectValidate {
    width: 314px;
  }

  & div[class*="reactSelect__option--is-selected"] {
    svg > path {
      fill: var(--color-neutral-00);
    }
  }

  &_contentSelect {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: map-get($spacing, xxs);

    .icon {
      flex-shrink: 0;
    }
  }
}

@import "../../../../assets/scss/avocadoVariables";
@import "../../../../assets/scss/mixin";

.successMessage {
  font-size: map-get($sizeText, 16);
  font-weight: map-get($fonts, medium);
  color: map-get($colors, foris-white);
}

.sessionsDelete {
  &_header {
    padding: map-get($spacing, xs) 0 0;

    .headerTitle {
      height: 100%;
    }

    .HeaderMenuItem {
      white-space: nowrap;
    }
  }

  &_content {
    padding: map-get($spacing, lg);
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1160px;

    .contentInfo {
      margin: 0 0 map-get($spacing, sm);
      box-shadow: map-get($shadow, md);
    }

    .holidaysWrapper {
      margin: 0 0 map-get($spacing, lg) 0;
      border-radius: map-get($radius, md);
      box-shadow: map-get($shadow, sm);
      background-color: map-get($colors, foris-white);
      padding: map-get($spacing, md) map-get($spacing, md) map-get($spacing, sm)
        map-get($spacing, md);

      &_title {
        margin: 0 0 map-get($spacing, xs);
        font-size: map-get($sizeText, 12);
        font-weight: map-get($fonts, bold);
        color: map-get($colors, layout-grey-90);
      }

      &_list {
        display: flex;
        flex-direction: column;
        gap: map-get($spacing, xxs);
        max-height: 183px;
        overflow: auto;
        padding: 0 0 map-get($spacing, xxs);

        .holiday {
          font-size: map-get($sizeText, 12);
          color: map-get($colors, layout-grey-90);
        }
      }
    }

    .contentForm {
      &_title {
        font-size: map-get($sizeText, 12);
        font-weight: map-get($fonts, bold);
        color: map-get($colors, layout-grey-90);
        margin: 0 0 map-get($spacing, xs);
      }

      &_control {
        display: grid;
        grid-template-columns: auto 44px auto;
        justify-content: flex-start;
        align-items: center;
        gap: map-get($spacing, xxs);
        font-size: map-get($sizeText, 12);
        color: map-get($colors, layout-grey-90);

        & input {
          width: 100%;
        }
      }

      &_actions {
        display: flex;
        align-items: center;
        gap: map-get($spacing, sm);
        margin: map-get($spacing, lg) 0 0;

        .actionButton {
          font-weight: map-get($fonts, medium);
        }
      }
    }
  }
}

.floatButton {
  position: fixed;
  right: 12px;
  bottom: -100%;
  opacity: 0;

  &.floatButton__visible {
    animation: floatIn 0.5s ease-in-out;
    animation-fill-mode: forwards;
  }

  &.floatButton__hidden {
    animation: floatOut 0.5s ease-in-out;
    animation-fill-mode: forwards;
  }

  &.floatButton__outlined {
    background-color: var(--color-neutral-00);
    border: 1px solid var(--color-primary-50);
    color: var(--color-primary-50);
    fill: var(--color-primary-50);
  }
}

@keyframes floatIn {
  0% {
    bottom: -100%;
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    bottom: 36px;
    opacity: 1;
  }
}

@keyframes floatOut {
  0% {
    bottom: 36px;
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    bottom: -100%;
    opacity: 0;
  }
}

@import "../../assets/scss/mixin";
@import "../../assets/scss/variables";

.cntVacancies {
  position: relative;
  .contentBundle {
    padding: 2rem;
    .tab {
      margin: 0 0 1.5rem;
      border-bottom: 2px solid map-get($colors, layout-grey-10);
    }
    .loader {
      min-height: calc(100vh - 50px);
    }
    .content {
      padding: 0.25rem 0;
      .cntCard {
        @include box(foris-white, 4px);
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
        margin: 0 0 0.75rem;
        &:last-of-type {
          margin: 0;
        }
        .headerCard {
          padding: 1rem;
          border-top-left-radius: 0.25rem;
          border-top-right-radius: 0.25rem;
          &_circle {
            @include box(foris-white, 50%);
            width: 12px;
            height: 12px;
            margin: 0 0.75rem 0 0;
          }
          &_title {
            @include text(1rem, foris-white, medium);
            margin: 0;
            padding: 0 0.5rem 0 0;
          }
          &_code {
            @include text(1rem, foris-white);
          }
        }
      }
    }
    .cardError {
      margin: 1.5rem 0 0;
      .listError {
        margin: 0 0 0.75rem;
        &:last-of-type {
          margin: 0;
        }
        &:first-of-type {
          margin: 0.75rem 0;
        }
        &_subTitle {
          @include text(0.875rem, message-negative-50, medium);
          padding: 0 0 0.25rem;
        }
        &_item {
          @include text(0.75rem, layout-grey-90);
          padding: 0 0 0.125rem;
          &:last-child {
            padding: 0;
          }
        }
      }
    }
    .footer {
      padding: 1.5rem 0;
      &_btn {
        min-width: 130px;
        &:first-of-type {
          margin: 0 1.5rem 0 0;
        }
      }
    }
  }
}

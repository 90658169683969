@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/mixin";

.clientCodeManagement {
  padding: map-get($spacing, lg);
  display: flex;
  flex-direction: column;
  gap: map-get($spacing, xl);

  .tooltipLabel {
    list-style: disc;
    padding-left: map-get($spacing, lg);
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, xxs);
  }

  &_title {
    font-size: map-get($sizeText, 22);
    color: map-get($colors, layout-grey-90);
  }

  &_control {
    .label {
      display: block;
      font-size: map-get($sizeText, 12);
      color: map-get($colors, layout-grey-90);
      font-weight: map-get($fonts, bold);
      margin: 0 0 map-get($spacing, xxs);
    }

    .textField {
      max-width: 72px;

      & input {
        width: 100%;
      }
    }

    .errorLabel {
      color: map-get($colors, message-negative-50);
      font-size: map-get($sizeText, 12);
      margin-top: map-get($spacing, xxs);
    }

    .errorCard {
      margin-top: map-get($spacing, lg);
      max-width: 620px;
    }
  }

  &_actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: map-get($spacing, sm);

    .actionButton {
      font-weight: map-get($fonts, medium);
    }
  }
}

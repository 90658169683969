@import "../../../assets//scss/mixin";
@import "../../../assets/scss/variables";

.multiSelect {
  @include text(14px, darwined-blue-10, bold);
  background-color: map-get($colors, layout-grey-5);
  box-shadow: 0 0 3px rgba(52, 58, 64, 0.2);
  margin-top: 1rem;
  min-width: 100%;

  button {
    border-radius: 0;
  }

  &_header {
    @include text(14px, darwined-blue-10, bold);
    background-color: map-get($colors, layout-grey-5);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
  }
  &_termPartByCategory {
    @include text(14px, darwined-blue-30, bold);
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    &_termParts {
      color: green !important;
      display: flex;
      justify-content: flex-start;
      margin-top: 0.3rem;
      flex-wrap: wrap;
      gap: 0.5rem;
      &_item {
        width: auto;
        margin-right: 1rem;
        &_label {
          @include text(12px, layout-grey-90, bold);
          margin-left: 0.5rem;
          white-space: nowrap;
          pointer-events: auto !important;
          &_disabled {
            color: map-get($colors, layout-grey-30);
            &::before,
            &::after {
              transform-origin: none !important;
              transform: none !important;
            }
            &:hover::before,
            &:hover::after {
              transform-origin: none !important;
              transform: none !important;
            }
            &::before {
              visibility: hidden !important;
            }
            &::after {
              @include text(12px, layout-grey-90);
              background-color: map-get($colors, foris-white);
              height: 30px;
              padding: 8px 12px;
              box-shadow: 0px 0px 3px 2px rgba(52, 58, 64, 0.3);
              border-radius: 2px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 0;
              left: 0 !important;
            }
          }
        }
      }
    }
  }
  &_count {
    @include text(10px, layout-grey-50);
  }
  &_buttonsSection {
    display: flex;
    @include text(14px, darwined-blue-10, bold);
    &_button {
      &:disabled {
        cursor: default !important;
      }
    }
  }
  &_discardButton {
    @include text(0.875rem, darwined-blue-30, bold);
    background-color: map-get($colors, foris-white);
    border: 1px solid map-get($colors, darwined-blue-30);
    margin-right: 1rem;
    &:hover {
      background-color: map-get($colors, layout-grey-10);
    }
    &:disabled {
      color: map-get($colors, foris-white);
      cursor: default;
      background-color: map-get($colors, layout-grey-30);
      border: none;
    }
  }
  &_unoutlined_button {
    @include text(0.875rem, darwined-blue-30, bold);
    border: none !important;
    width: 100%;
    display: flex;
    justify-content: space-between;
    &_chevron {
      border: none !important;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  &_hiddenContent {
    padding: 0;
  }
}

@import "../../../assets/scss/mixin";
@import "../../../assets/scss/variables";

.component_radio {
  display: flex;
  align-items: flex-end;
  &.component_radio--disabled {
    .cnt_option {
      &-label {
        color: map-get($colors, layout-grey-30);
      }
    }
  }
  .cnt_option {
    @include text(0.875rem, layout-grey-90);
    display: block;
    position: relative;
    padding: 0 0 0 1.625rem;
    cursor: pointer;
    user-select: none;
    &-label {
      @include text(0.875rem, layout-grey-90);
    }
    &-input {
      width: 0;
      height: 0;
      opacity: 0;
      cursor: pointer;
      &:checked ~ .cnt_option-check {
        background: map-get($colors, foris-white);
      }
      &:checked ~ .cnt_option-check:after {
        display: block;
      }
      &:disabled ~ .cnt_option-check {
        background: map-get($colors, layout-grey-10);
        border: 1px solid map-get($colors, layout-grey-30);
        cursor: no-drop;
      }
      &:checked:disabled ~ .cnt_option-check::after {
        display: block;
        background: map-get($colors, layout-grey-70);
        border: solid map-get($colors, layout-grey-70);
        cursor: no-drop;
      }
    }
    &-check {
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      background: map-get($colors, foris-white);
      border: 1px solid map-get($colors, darwined-blue-50);
      border-radius: 50%;
      &::after {
        content: "";
        position: absolute;
        display: none;
        left: 3px;
        top: 3px;
        width: 10px;
        height: 10px;
        background: map-get($colors, darwined-blue-50);
        border-radius: 50%;
      }
    }
  }
}

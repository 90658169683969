@import "../../../assets/scss/avocadoVariables";
@import "../../../assets/scss/mixin";
@import "../../../assets/scss/variables";

.cntCard {
  @include box(foris-white, 4px);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
  margin: 0 0 1.5rem;
  &:last-of-type {
    margin: 0;
  }
  .headerCard {
    padding: 1rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    &_circle {
      @include box(foris-white, 50%);
      width: 12px;
      height: 12px;
      margin: 0 0.5rem 0 0;
    }
    &_title {
      @include text(0.875rem, foris-white, medium);
      margin: 0;
      padding: 0 0.5rem 0 0;
    }
    &_code,
    &_codeManagement {
      @include text(0.875rem, foris-white);
      text-decoration: underline;

      &__underline {
        text-decoration: underline;
      }
    }
    &_codeManagement {
      text-decoration: none;
      margin: 0 0 0 map-get($spacing, xxs);
    }
    &_linkForm {
      @include text(0.75rem, foris-white);
      padding: 0 0 0 0.75rem;
      .text {
        text-decoration: underline;
      }
      .icon {
        padding: 0 0 0 0.25rem;
      }
    }
  }
  .cntEdit {
    padding: 1.5rem;
    background: map-get($colors, layout-white);
    flex: 1;
    max-width: 400px;
    border-radius: 0 0 0.25rem 0;
    &_title {
      @include text(12px, layout-grey-50);
      padding: 0 0 12px;
    }
    .headerList {
      list-style: none;
      padding: 0 0 0.75rem;
      margin: 0;
      &_item {
        @include text(0.75rem, layout-grey-90, bold);
        flex: 1;
      }
    }
    .infoList {
      @extend .headerList;
      min-height: 50px;
      padding: 0 0 1rem;
      &_item {
        @include text(0.875rem, layout-grey-90);
        text-transform: uppercase;
        flex: 1;
        &:last-of-type {
          position: relative;
        }
        .link {
          color: map-get($colors, darwined-blue-10);
          &:hover {
            color: map-get($colors, darwined-blue-30);
          }
          &:active {
            color: map-get($colors, darwined-blue-50);
          }
        }

        &.infoList_item__share {
          align-items: center;
          font-size: 0.8rem;
          font-weight: map-get($fonts, medium);
        }
        .shareCircle {
          display: flex;
          align-items: center;
          .circleList {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin: 0 0.25rem 0 0;
          }
        }
      }
    }
  }
}

@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixin";

.modalState {
  min-width: 360px;
  max-width: 400px;
  width: auto;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px 0 rgba(map-get($colors, layout-grey-90), 0.25);
  .header {
    height: 76px;
    position: relative;
    &_cntIcon {
      @include flex(center, center);
      width: 80px;
      height: 80px;
      border-radius: 50%;
      box-shadow: 0 -2px 10px 0 rgba(map-get($colors, layout-grey-90), 0.15);
      position: absolute;
      bottom: -40px;
      margin: 0 auto;
      left: 0;
      right: 0;
      .icon {
        color: map-get($colors, foris-white);
      }
    }
  }
  .content {
    min-height: 300px;
    padding: 3.75rem 2rem 2rem;
    &_title {
      padding: 0 0 2rem;
    }
    &_children {
      @include text(0.75rem, layout-grey-70);
      text-align: center;
      padding: 0 0 1.5rem;
    }
    .footer {
      align-self: flex-end;
      &_btn {
        flex: 1;
        margin: 0 0 0 1.5rem;
        &:first-child {
          margin: 0;
        }
      }
    }
  }

  &.modalState__confirm {
    .header {
      background: map-get($colors, message-positive-50);
      &_cntIcon {
        background: map-get($colors, message-positive-50);
      }
    }
    .content {
      &_title {
        @include text(1.375rem, message-positive-50, bold);
        text-align: center;
      }
    }
  }
  &.modalState__warning {
    .header {
      background: map-get($colors, message-alert-50);
      &_cntIcon {
        background: map-get($colors, message-alert-50);
      }
    }
    .content {
      &_title {
        @include text(1.375rem, message-alert-50, bold);
        text-align: center;
      }
    }
  }
  &.modalState__error {
    .header {
      background: map-get($colors, message-negative-50);
      &_cntIcon {
        background: map-get($colors, message-negative-50);
      }
    }
    .content {
      &_title {
        @include text(1.375rem, message-negative-50, bold);
        text-align: center;
      }
    }
  }
}

@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/variables";

.blockRange {
  padding: 0.75rem 0 0;
  &_bottomBorder {
    padding-bottom: 0.7rem;
    border-bottom: 1px solid map-get($colors, layout-grey-20) !important;
  }
  &_dashIcon {
    color: map-get($colors, layout-grey-30) !important;
    align-self: flex-end;
    padding-bottom: 0.8rem;
    padding-left: 1.5rem;
  }
  &__days_label {
    @include text(0.875rem, layout-grey-90, regular);
    margin-bottom: 10px;
  }
  .options {
    margin: 0 0 0.75rem;
    display: flex;
    flex-wrap: nowrap;

    &:last-child {
      margin: 0;
    }
    &_item {
      margin: 0 0 0 1.5rem;
      &:first-of-type {
        margin: 0;
      }
      &.options_item__checkbox {
        input {
          border: 1px solid map-get($colors, layout-grey-30);
        }
        width: auto;
      }
      &_primaryCheckbox {
        width: auto;
      }
    }
  }
  &_input {
    width: 80px;
  }
  .errorText {
    @include text(0.75rem, layout-grey-90);
  }
}

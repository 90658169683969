@import "../../../assets/scss/mixin";
@import "../../../assets/scss/avocadoVariables";

.codeText {
  font-weight: 500;
}

.columnLink,
.columnCell {
  word-break: break-word;
}

.columnLink {
  color: var(--color-primary-50);
  display: block;
}

.columnPill {
  width: fit-content;
}

.columnCell {
  color: map-get($colors, layout-grey-90);
  padding: 0 0.25rem 0 0;
  display: block;
}

.columnOpenGroup {
  display: flex;
  justify-content: center;
  align-items: center;
}

.columnLabels {
  display: flex;
  flex-wrap: wrap;
  gap: map-get($spacing, xxs);
}

@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/variables";

.linkSelector {
  .linkLabelWrapper {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: map-get($spacing, xs);
    width: calc(100% + 20px);

    &.linkLabelWrapper__selected {
      background-color: map-get($colors, layout-grey-5);
      padding: map-get($spacing, xs) map-get($spacing, sm);
      margin: -8px -12px;

      .linkOptionLabel,
      .linkOptionLabel .linkOptionLabel_secondaryLabel.linkOptionLabel_secondaryLabel {
        color: map-get($colors, layout-grey-50);
      }
    }

    &_icon {
      color: map-get($colors, layout-grey-50);
    }

    .linkOptionLabel {
      @include text(12px, layout-grey-70, 300);
      display: flex;
      flex-direction: column;
      text-align: left;
      align-items: flex-start;
      white-space: normal;

      &.linkOptionLabel__groups {
        gap: map-get($spacing, xxs);

        .linkOptionLabel_secondaryLabel {
          font-size: map-get($sizeText, 10);
          color: map-get($colors, layout-grey-50-2);
        }
      }
    }
  }
}

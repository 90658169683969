@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/mixin";

.infoContainer {
  margin-bottom: map-get($spacing, md);
  h3 {
    font-size: map-get($sizeText, 16) !important;
    color: map-get($colors, layout-grey-90) !important;
  }
}

.info {
  margin-top: map-get($spacing, md);
  display: flex;
  flex-direction: column;
  &_title {
    @include text(map-get($sizeText, 14), layout-black, bold);
  }
  &_message {
    @include text(map-get($sizeText, 14), layout-black);
  }
}

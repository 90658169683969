@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/variables";

.advanceTable {
  margin: 1.5rem 0 0;
}

.cardState {
  margin: 1.5rem 0 0;
}

.cell {
  &_bold {
    font-weight: map-get($fonts, medium);
    text-transform: uppercase;
  }
}

.form {
  padding: 2rem 0 0;
  max-width: 50%;
  .item {
    padding: 0 0 2rem;
    &:last-child {
      padding: 0;
    }
    &_title {
      @include text(1rem, layout-grey-90, medium);
      margin: 0 0 0.75rem;
      padding: 0 0 0.125rem;
      border-bottom: 1px solid map-get($colors, layout-grey-90);
    }
    &_option {
      margin: 0 1rem 0 0;
      &:last-child {
        margin: 0;
      }
    }
  }
  .errorCard {
    margin: 0.75rem 0 0;
    @include text(0.75rem, layout-grey-90);
  }
}

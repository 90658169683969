@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixin";
.cnt_range {
  .range_content {
    height: 36px;
    display: flex;
    width: 100%;
    position: relative;
    &.range_content__disabled {
      .range_content_pointer {
        @include box(layout-grey-10, 50%, layout-grey-30);
        .detail_range {
          @include text(0.625rem, layout-grey-30, bold);
          @include box(layout-grey-10, 0.25rem, layout-grey-30);
          &::before,
          &::after {
            border-top-color: map-get($colors, layout-grey-10);
          }
          &::before {
            border-top-color: map-get($colors, layout-grey-30);
          }
        }
      }
    }
    &_init,
    &_end {
      @include text(0.874rem, layout-grey-10);
      position: absolute;
      bottom: -10px;
    }
    &_init {
      left: 0;
    }
    &_end {
      right: 0;
    }
    &_line {
      height: 3px;
      width: 100%;
      background: map-get($colors, layout-grey-10);
      border-radius: 1px;
      align-self: center;
    }
    &_pointer {
      @include box(darwined-blue-30, 50%, darwined-blue-50);
      height: 20px;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
      outline: none;
      z-index: map-get($zIndex, basic) !important;
      .detail_range {
        @include text(0.625rem, layout-grey-90, bold);
        @include box(foris-white, 0.25rem, layout-grey-30);
        position: absolute;
        bottom: 30px;
        margin: 0;
        padding: 0.365rem 0;
        width: 70px;
        text-align: center;
        &::before,
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 0;
          height: 0;
          border: 8px solid transparent;
          border-top-color: map-get($colors, foris-white);
          border-bottom: 0;
          margin-left: -8px;
          margin-bottom: -7px;
          z-index: map-get($zIndex, element);
          display: block;
        }
        &::before {
          bottom: -2px;
          z-index: map-get($zIndex, basic);
          border-top-color: map-get($colors, layout-grey-30);
        }
      }
    }
  }
}

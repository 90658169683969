@import "./variables";
@import "./mixin";

.react-tabs__tab {
  @include text(1rem, layout-grey-50);
  padding: 4px 0 !important;
  margin: 0 0.875rem 0 0;
  &:last-child {
    margin: 0;
  }
  &.react-tabs__tab--selected {
    @include box(transparent, 0);
    color: map-get($colors, darwined-blue-30);
    border-bottom: 2px solid map-get($colors, darwined-blue-30);
  }
  &-list {
    margin: 0;
    border-bottom: 2px solid map-get($colors, layout-grey-10) !important;
  }
  &-panel {
    &.react-tabs__tab-panel--selected {
      border-top: none;
    }
  }
}

@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/variables";

.items {
  display: inline-flex;
  gap: map-get($spacing, xxl);
  width: auto;
  flex-wrap: wrap;

  &:not(:first-child) {
    margin: 0 0 map-get($spacing, xxl);
  }

  &:last-of-type {
    margin: 0;
  }

  &.items__independent {
    .items_details {
      .detailsContent {
        white-space: unset;
        line-break: anywhere;
      }
    }
  }

  &_details {
    .detailsTitle {
      color: var(--color-gray-10);
      font-weight: map-get($fonts, bold);
    }

    .detailsContent {
      color: var(--color-gray-10);
      display: flex;
      font-weight: map-get($fonts, regular);
      max-width: 100%;
      overflow: hidden;
      padding: map-get($spacing, xxs) 0 0 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: auto;

      & > a {
        color: inherit;
      }

      .item {
        padding: 0 0 1.5rem;

        &_info {
          @include text(1rem, layout-grey-90);
          &.item_info__stripe {
            @include text(0.875rem, layout-grey-90);
            margin: 0;
          }
        }
      }
    }
  }
}

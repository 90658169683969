@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/legacyBreakpoints";
@import "../../../../../assets/scss/mixin";

.dataGrid {
  ul {
    overflow: visible !important;
  }

  &_rightActions {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;

    .actionLink {
      margin: 0 map-get($spacing, sm) 0 0;
      color: map-get($colors, darwined-blue-30);
    }

    .actionFilters {
      width: 100%;
      grid-column-start: 2;
      align-items: center;
    }
  }
}

.noResult {
  margin-top: 1.4rem;
  width: 83.3%;
  &_title {
    @include text(map-get($sizeText, 22), layout-black);
  }
  &_suggestion {
    @include text(map-get($sizeText, 14), layout-black);
  }
}

.table {
  margin: 0.875rem 0 0 0;
  width: 83.3%;
  min-width: 1244px;

  &__crn {
    display: flex;
    @include text(14px, darwined-blue-30);
    line-break: anywhere;
    padding: 0 0.25rem 0 0;
  }

  .tableCard {
    background-color: map-get($colors, layout-grey-5);
    &__button {
      background-color: transparent;
      &:hover,
      &:active {
        background-color: transparent;
      }
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
    &__title {
      @include text(map-get($sizeText, 16), layout-grey-70);
    }
    &__link {
      @include text(map-get($sizeText, 14), darwined-blue-30, bold);
      margin: 0 0 0 map-get($spacing, lg);
      cursor: pointer;
    }
    &_pager {
      margin: map-get($spacing, lg) 0;
    }
    &_info {
      @include text(map-get($sizeText, 14), layout-grey-70);
      flex: 1;
      display: flex;
      justify-content: space-between;
      margin: 0 map-get($spacing, md);
      &_left {
        display: flex;
        justify-content: space-between;
        gap: map-get($spacing, xl);
        align-items: center;
        &_checkbox {
          width: auto;
        }
        &_button {
          @include text(map-get($sizeText, 14), darwined-blue-10, bold);
          background-color: transparent !important;
          border: none;
          padding: 0;
        }
      }
      &__donut {
        align-items: flex-end;
        min-width: 250px;
      }
    }

    &_total {
      background-color: map-get($colors, foris-white);
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
      padding: map-get($spacing, md) 27% map-get($spacing, md) map-get($spacing, md);
      display: flex;
      justify-content: space-between;

      &_label {
        @include text(map-get($sizeText, 14), layout-grey-70, bold);
      }
    }

    &_line {
      @include box(foris-white, map-get($radius, md), layout-grey-10);
      margin: map-get($spacing, md) 0;
    }
  }

  .dataCircle {
    margin: 0 map-get($spacing, sm) 0 0;
  }

  .dataLabels {
    width: 48px;
  }
}

.reportModal {
  width: 500px;
  button {
    margin-left: 10px;
  }
}

.dateSelector {
  &__container {
    display: flex;
    justify-content: flex-start;
    margin-top: map-get($spacing, md);
    gap: 10px;
  }

  &__item {
    width: 40%;
  }
}

.transparentButton {
  background-color: transparent !important;
  &:hover {
    border: none;
    background-color: transparent;
  }
  &:active {
    border: none;
    background-color: transparent;
  }
  &:disabled {
    border: none;
    cursor: default;
  }
}

.groupTypePill {
  gap: map-get($spacing, xxs);
}

.groupLink {
  @include text(0.875rem, darwined-blue-30, normal);
  cursor: pointer;
}

@media (max-width: map-get($legacyBreakpoints, xl)) {
  .table,
  .noResult {
    width: 100% !important;
    min-width: unset;
  }
}

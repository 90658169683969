@import "../../../../assets/scss/avocadoVariables";
@import "../../../../assets/scss/mixin";

.sharedBlocks {
  display: flex;
  flex-direction: column;
  gap: map-get($spacing, xxs);
  width: 100%;

  &_grid {
    display: grid;
    grid-template-columns: 96px 10px 96px auto;
    gap: map-get($spacing, xs);
    align-items: center;
  }

  &_separator {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_error {
    font-size: map-get($sizeText, 12);
    color: map-get($colors, message-negative-50);
  }
}

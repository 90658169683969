@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/variables";

.advanceTable {
  margin: 1.5rem 0 0;
}

.advanceCell {
  &_bold {
    font-weight: map-get($fonts, medium);
    text-transform: uppercase;
  }
}

@import "../../../../../../assets/scss/variables";
@import "../../../../../../assets/scss/mixin";

.cntSingleEvent {
  &_divider {
    padding: 1.5rem;
    border-bottom: 1px solid map-get($colors, layout-grey-10);
  }
  &_btnAdd {
    @include text(14px, layout-grey-50);
    background: transparent;
    margin: 1rem 0 0;
    cursor: pointer;
    outline: none;
    &:disabled {
      color: map-get($colors, layout-grey-10);
      .iconAdd {
        color: map-get($colors, layout-grey-10);
        border-color: map-get($colors, layout-grey-10);
      }
    }
    .iconAdd {
      @include box(transparent, 50%, layout-grey-30);
      @include flex(center, center);
      width: 18px;
      height: 18px;
      margin: 0 0.25rem 0 0;
      color: map-get($colors, layout-grey-90);
    }
  }
}

@media (max-width: map-get($breakpoints, xs)) {
  .cntSingleEvent {
    &_divider {
      padding: 1rem;
    }
  }
}

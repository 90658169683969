@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/variables";

.card {
  flex: 1;
  &_title {
    @include text(1rem, layout-grey-90, bold);
    padding: 0 0 0.75rem;
  }
  .item {
    padding: 0 0 0.75rem;
    &:last-child {
      padding: 0;
    }
    &.item__same {
      .item_label,
      .item_info {
        color: map-get($colors, layout-grey-30);
      }
    }
    &_label {
      @include text(0.75rem, layout-grey-70, medium);
      padding: 0 0 0.25rem;
    }
    &_info {
      @include text(0.875rem, layout-grey-90);
      .bold {
        font-weight: map-get($fonts, medium);
      }
    }
  }
}

@import "../../../assets/scss/avocadoVariables";
@import "../../../assets/scss/mixin";
@import "../../../assets/scss/variables";

.foris_input {
  @include box(foris-white, 0.3125rem, layout-grey-30);
  @include text(0.875rem, layout-grey-70);
  width: 100%;
  min-height: 36px;
  padding: 0.5rem 0.75rem;
  flex-grow: 1;
  outline: none;
  &::placeholder {
    color: map-get($colors, layout-grey-30);
  }
  &:focus {
    border: 1px solid map-get($colors, darwined-blue-10);
  }
  &:hover {
    border: 1px solid map-get($colors, layout-grey-30);
  }
  &.foris_input__error {
    @include box(foris-white, 0.3125rem, message-negative-50);
  }
}

@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/legacyBreakpoints";
@import "../../../../../assets/scss/mixin";

.noResult {
  margin-top: 1.4rem;
  width: 83.3%;
  &_title {
    @include text(map-get($sizeText, 22), layout-black);
  }
  &_suggestion {
    @include text(map-get($sizeText, 14), layout-black);
  }
}

.state {
  display: flex;
  gap: 0.3rem;
  align-items: center;
  justify-content: space-between;
  width: 28%;
  max-width: 70px;
}

.table {
  margin: 0.875rem 0 0 0;
  width: 100%;
  .tableCard {
    &__header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 80%;
      }
      &__button {
        background-color: transparent;
        padding-top: 0;
        padding-bottom: 0;
        border: none;
        &:active {
          background-color: transparent;
        }
        &:focus {
          border: none;
        }
      }
    }
    background-color: map-get($colors, layout-grey-5);
    &__title {
      @include text(map-get($sizeText, 16), layout-grey-70);
    }
    &__link {
      @include text(map-get($sizeText, 14), darwined-blue-30, bold);
      margin: 0 0 0 map-get($spacing, lg);
      cursor: pointer;
    }
    &__icon {
      @include text(map-get($sizeText, 14), darwined-blue-30, bold);
      margin: 0 0.375rem;
      &_disabled {
        color: map-get($colors, layout-grey-30);
      }
    }
    &_pager {
      margin: map-get($spacing, lg) 0;
    }
    &_info {
      @include text(map-get($sizeText, 14), layout-grey-70);
      flex: 1;
      display: flex;
      justify-content: space-between;
      margin: 0 map-get($spacing, md);
      &_left {
        display: flex;
        justify-content: flex-start;
        gap: map-get($spacing, xl);
        &_checkbox {
          width: auto;
        }
        &_button {
          @include text(map-get($sizeText, 14), darwined-blue-10, bold);
          border: none;
          padding: 0;
          &:hover {
            border: none;
            background-color: transparent;
          }
          &:active {
            border: none;
            background-color: transparent;
          }
          &:disabled {
            border: none;
            cursor: default;
          }
        }
      }
      &__donut {
        align-items: flex-end;
        min-width: 250px;
      }
    }

    &_total {
      background-color: map-get($colors, foris-white);
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
      padding: map-get($spacing, md) 27% map-get($spacing, md) map-get($spacing, md);
      display: flex;
      justify-content: space-between;

      &_label {
        @include text(map-get($sizeText, 14), layout-grey-70, bold);
      }
      &_number {
        @include text(map-get($sizeText, 14), layout-grey-90);
      }
    }

    &_line {
      @include box(foris-white, map-get($radius, md), layout-grey-10);
      margin: map-get($spacing, md) 0;
    }
  }

  .dataCircle {
    margin: 0 map-get($spacing, sm) 0 0;
  }

  .dataLabels {
    width: 48px;
  }
}

@media (max-width: map-get($legacyBreakpoints, xl)) {
  .table,
  .noResult {
    width: 100% !important;
  }
}

@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/variables";

.cntEditTag {
  display: grid;
  grid-template-columns: 164px 1fr;
  gap: map-get($spacing, xl);
  align-items: flex-start;

  &:not(:last-of-type) {
    padding: 0 0 map-get($spacing, sm);
    margin: 0 0 map-get($spacing, sm);
    border-bottom: map-get($borders, md) solid var(--color-gray-90);
  }

  &.cntEditTag__addMargin {
    margin: 0.75rem 0 0;
  }

  &_actions {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: map-get($spacing, xs);

    .actionsTitle {
      color: var(--color-gray-10);
      display: flex;
    }

    .actionsLink {
      color: var(--color-gray-00);

      svg > path {
        fill: var(--color-gray-00);
      }
    }
  }

  &_contentTags {
    gap: map-get($spacing, lg);
    display: flex;
    flex-direction: column;

    .tagCategory {
      &_name {
        color: var(--color-gray-10);
        display: block;
        margin: 0 0 map-get($spacing, xs);
      }

      &_labels {
        display: flex;
        flex-wrap: wrap;
        gap: map-get($spacing, xs);
      }
    }

    .tagGroup {
      background: var(--color-gray-85);
      border: map-get($borders, md) solid transparent;

      &:hover {
        background: var(--color-gray-70);
        border-color: var(--color-gray-70);
      }

      * > span {
        color: var(--color-gray-30);
      }

      &.tagGroup__system {
        .tagGroup_name {
          &::before {
            display: none;
          }
        }
      }

      &_code {
        display: inline-flex;
        text-transform: uppercase;
        color: var(--color-gray-30);
      }

      &_name {
        display: inline-flex;
        color: var(--color-gray-30);

        &::before {
          content: "|";
          padding: 0 map-get($spacing, xxs);
        }
      }
    }
  }
}

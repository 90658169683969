@import "../../../assets/scss/avocadoVariables";
@import "../../../assets/scss/mixin";
@import "../../../assets/scss/variables";

.sharedCheckbox {
  width: 100%;
  max-width: 760px;

  &_field {
    padding: 0;
  }

  &_assignments {
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, sm);
  }

  &_days {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: map-get($spacing, sm) map-get($spacing, xxs);

    .checkboxDay {
      max-width: 80px;
    }
  }

  &_error {
    font-size: map-get($sizeText, 12);
    color: map-get($colors, message-negative-50);
    margin: 0;
    padding: map-get($spacing, xs) 0 0;
  }
}

.shared_check {
  padding: 0 1.5rem;
  &_item {
    flex: 0 0 14%;
    min-width: 10%;
    margin: 0 0 1rem;
    &_row {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
    }
  }
  &_select {
    flex: 0 0 14%;
    min-width: 10%;
    margin: 0 0 1rem;
  }
  &_title {
    @include text(0.975rem, layout-grey-50, medium);
  }
  &_check {
    width: 100%;
    margin: 0 0 0.75rem;
  }
}

.error_text {
  @include text(0.75rem, message-negative-50);
  margin: 0;
  padding: 0.625rem 0 0 1rem;
}

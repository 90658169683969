@import "../../../../assets//scss/avocadoVariables";
@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixin";

.cardResult {
  margin: 0.5rem 0 0;
  cursor: pointer;

  &_content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: map-get($spacing, xs);
  }

  &:first-of-type,
  &:first-child,
  &:nth-child(1) {
    margin: 0;
  }

  &_classroomName {
    @include text(20px, darwined-blue-30, bold);
    padding: 0 0 map-get($spacing, xxs);
    text-transform: uppercase;
    grid-column-end: span 4;
  }
  &_item {
    .label {
      @include text(10px, layout-grey-90, bold);
    }
    .info {
      @include text(14px, layout-grey-90);
    }
  }
}

@media screen and (max-width: 768px) {
  .cardResult {
    &_classroomName {
      grid-column-end: span 2;
    }

    &_content {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@import "../../../assets/scss/avocadoVariables";
@import "../../../assets/scss/mixin";

.cntEmpty {
  &_cardNotification {
    width: 584px;
  }

  &_actionsBtn {
    display: flex;
    gap: map-get($spacing, sm);
    margin: map-get($spacing, xxl) 0 0;
  }
}

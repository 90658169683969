@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/variables";

.formItem {
  padding: 2rem 0 0;
  &:first-of-type {
    padding: 0;
  }
  .header {
    flex-wrap: nowrap;
    padding: 0 0 0.25rem;
    border-bottom: 1px solid map-get($colors, layout-grey-50);
    &_title {
      @include text(1rem, layout-grey-90, medium);
      width: auto;
    }
    &_check {
      width: auto;
    }
    &_titleWithinfoMessage {
      display: flex;
      gap: 1rem;
      align-items: center;

      &_title {
        @include text(1rem, layout-grey-90, medium);
        width: auto;
      }
      &_infoMessage {
        @include text(0.75rem, layout-grey-70, regular);
        text-align: center;
        vertical-align: middle;
      }
    }
  }
}

.btnTrash {
  @include flex(center, center);
  width: 40px;
  background: transparent;
  color: map-get($colors, darwined-blue-30);
  cursor: pointer;
  border: none;
  outline: none;
  &:hover {
    color: map-get($colors, darwined-blue-50);
  }
  &:active {
    color: map-get($colors, darwined-blue-70);
  }
  &__disabled {
    cursor: default;
    color: map-get($colors, layout-grey-30);
    &:active,
    &:hover {
      color: map-get($colors, layout-grey-30);
    }
  }
}

@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/avocadoVariables";

.groupForm {
  display: flex;
  flex-direction: column;
  padding: map-get($spacing, md);
  gap: map-get($spacing, lg);
  border: 1px solid map-get($colors, layout-grey-30);
  border-radius: 0.5rem;
  width: 100%;
  margin: map-get($spacing, lg) 0 0;

  &_title {
    font-size: map-get($sizeText, 16);
    line-height: map-get($sizeText, 18);
    font-weight: map-get($fonts, medium);
    color: map-get($colors, layout-grey-90);
  }

  &_fields {
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, lg);

    .formField {
      &_field {
        width: 100%;
        max-width: 300px;
      }
    }

    .formErrors {
      display: flex;
      flex-direction: column;
      gap: map-get($spacing, xs);
      margin: map-get($spacing, xxs) 0 0;

      &_error {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: map-get($spacing, xxs);
        color: map-get($colors, message-negative-50);
        font-size: map-get($sizeText, 14);
        line-height: map-get($sizeText, 16);
        font-weight: 400;
      }
    }
  }
}

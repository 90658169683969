// Test for transfrom this grid (flexbox version) in css: https://www.sassmeister.com/ <3

// old variables for old grid - avocado ui
$grid-cols: 12;
$side-map: (
  top: "_top",
  bottom: "_bottom",
  right: "_right",
  left: "_left",
  center: "_center",
);
$mapCols: (
  full: "",
  lg: "_lg",
  md: "_md",
  sm: "_sm",
  xs: "_xs",
  us: "_us",
);
$justifyContent: (
  between: space-between,
  around: space-around,
  center: center,
  left: flex-start,
  right: flex-end,
);
$oldBreakpoints: (
  lg: 1199.98px,
  md: 991.98px,
  sm: 767.98px,
  xs: 575.98px,
  us: 361.98px,
) !default;

// classes for old grid - avocado ui
// @mixin  that creates class type offset
@mixin decimalMargin() {
  @for $i from 1 through ($grid-cols - 1) {
    @each $key, $value in $side-map {
      &#{$value}_#{$i} {
        @if $i == $grid-cols {
          margin-#{$key}: 100%;
        } @else if $i != $grid-cols and $i != 0 {
          margin-#{$key}: (100 / ($grid-cols / $i)) * 1%;
        } @else if $i == 0 {
          margin-#{$key}: 0;
        }
      }
    }
  }
}

@function widthCol($columns, $total) {
  @return unquote((100 / $total) * $columns + "%");
}

// @mixin that creates class type -row, .col
@mixin contentFlexboxGrid($col) {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  @each $key, $value in $justifyContent {
    &.row#{$col}--#{$key} {
      justify-content: $value;
    }
  }

  @each $key, $value in $justifyContent {
    &.row_align#{$col}--#{$key} {
      align-items: $value;
    }
  }

  &.row--wrap#{$col} {
    flex-wrap: wrap;
  }
  &.row#{$col}--block {
    display: block;
  }
  &.row#{$col}--reverse {
    flex-direction: row-reverse;
  }
  &.row_nowrap#{$col} {
    flex-wrap: nowrap;
  }

  .col#{$col} {
    flex: 1;
  }

  @each $key, $value in $justifyContent {
    .col_align#{$col}--#{$key} {
      align-self: $value;
    }
  }
  .col_offset#{$col} {
    @include decimalMargin;
  }
  .col_hidden#{$col} {
    display: none;
  }
  .col_show#{$col} {
    display: flex;
  }

  @for $i from 1 through $grid-cols {
    .col#{$col}_#{$i} {
      flex: 0 0 widthCol($i, $grid-cols);
      max-width: widthCol($i, $grid-cols);
    }
  }
}

// TODO: fix to leave as global mixin
// @mixin  that creates class in its responsive versions
@mixin respond-to($media) {
  @if $media == full {
    @include contentFlexboxGrid(map-get($mapCols, full));
  } @else if $media == lg {
    @media (max-width: map-get($oldBreakpoints, lg )) {
      @include contentFlexboxGrid(map-get($mapCols, lg));
    }
  } @else if $media == md {
    @media (max-width: map-get($oldBreakpoints, md )) {
      @include contentFlexboxGrid(map-get($mapCols, md));
    }
  } @else if $media == sm {
    @media (max-width: map-get($oldBreakpoints, sm )) {
      @include contentFlexboxGrid(map-get($mapCols, sm));
    }
  } @else if $media == xs {
    @media (max-width: map-get($oldBreakpoints, xs )) {
      @include contentFlexboxGrid(map-get($mapCols, xs));
    }
  } @else if $media == us {
    @media (max-width: map-get($oldBreakpoints, us )) {
      @include contentFlexboxGrid(map-get($mapCols, us));
    }
  }
}

.container {
  margin: 0 auto;
  &-row {
    @include respond-to(full);
    @include respond-to(lg);
    @include respond-to(md);
    @include respond-to(sm);
    @include respond-to(xs);
    @include respond-to(us);
    // > [class*='col_'] {
    //   width: auto;
    //   height: auto;
    // }
  }
} //container

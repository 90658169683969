@import "./../../../assets/scss/mixin";
@import "./../../../assets/scss/variables";

.infoPanel {
  padding: 1.5rem 1.3rem;
  background: map-get($colors, foris-whte);
  margin: -0.25rem 0 0 0;

  &_weekly {
    margin: 0.75rem 0 0 0;
    background-color: map-get($colors, foris-white);
    border: 1px solid map-get($colors, layout-grey-10);
    border-radius: 0.25rem;
    padding: 1rem 0;
  }
}

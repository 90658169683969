@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/avocadoVariables";

.requirementsContent {
  display: grid;
  padding: 1.5rem;
  border-bottom: 1px solid map-get($colors, layout-grey-10);

  &_text {
    @include text(14, layout-grey-70, medium);
    padding: 0 0 1rem;
  }
  &_inputPerson {
    max-width: 140px;
  }

  &_info {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: map-get($spacing, xxs);
    margin: map-get($spacing, sm) 0 0;

    .infoIcon {
      flex-shrink: 0;
    }

    .infoIcon path {
      fill: map-get($colors, darwined-blue-50);
    }

    .infoText {
      font-size: map-get($sizeText, 12);
      color: map-get($colors, layout-grey-70);

      &_important {
        font-weight: map-get($fonts, medium);
      }
    }
  }
}

@media (max-width: map-get($breakpoints, xs)) {
  .requirementsContent {
    padding: 1rem;
  }
}

@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/mixin";

.container {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  max-width: 100px;

  &:hover .tooltip {
    visibility: visible;
  }
}

.tooltip {
  visibility: hidden;
  position: absolute;
  top: -2rem;
  width: 8.65rem;
  background-color: map-get($colors, layout-grey-90);
  color: map-get($colors, foris-white);
  text-align: center;
  padding: map-get($spacing, xs) 0.8rem;
  border-radius: 2px;
  box-shadow: 0 0 3px 0 rgba(52, 58, 64, 0.3);

  &::after {
    /* Small bottom arrow */
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: map-get($colors, layout-grey-90) transparent transparent transparent;
  }

  &__icon {
    max-width: 15%;
    &--pending {
      color: map-get($colors, message-alert-30);
    }
    &--alert {
      color: map-get($colors, layout-grey-90);
    }
    &--error {
      color: map-get($colors, message-negative-30);
    }
    &--warning {
      color: map-get($colors, message-alert-50);
    }
  }
}

.input {
  max-width: 85%;

  input {
    @include text(14px, layout-grey-40);
  }

  &--error {
    input {
      border-radius: 0.125rem;
      border: 1px solid map-get($colors, message-negative-30) !important;
    }
  }
}

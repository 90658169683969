@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/variables";

.radio_buttons_container {
  flex-direction: row;
}

.cntItemEdit {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 133px);
  position: absolute;
  top: 50px;
  bottom: 0;
  background: map-get($colors, foris-white);
  &.cntItemEdit__validationReport {
    background: map-get($colors, layout-white);
  }
  .content {
    padding: 2rem 2.5rem;
    .item {
      padding: 0 0 1.5rem;
      &_label {
        @include text(11.25px, layout-grey-90, bold);
        padding: 0 0 0.25rem;
      }
      &_info {
        @include text(1rem, layout-grey-90);
        &.item_info__stripe {
          @include text(0.875rem, layout-grey-90);
          margin: 0;
        }
      }
      &_tableRepeat {
        max-width: 650px;
      }
      &_select {
        width: 352px;
        &.item_select__day {
          width: 120px;
          margin: 0 1.625rem 0 0;
        }
        &.item_select__hour {
          width: 90px;
        }
      }
      .btnAdd {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid map-get($colors, darwined-blue-30);
        background: map-get($colors, foris-white);
        outline: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0 0 1rem;
        &_icon {
          width: 18px;
          color: map-get($colors, darwined-blue-30);
        }
        &:disabled {
          background: map-get($colors, layout-grey-10);
          border: 1px solid map-get($colors, layout-grey-30);
          .btnAdd_icon {
            color: map-get($colors, layout-grey-30);
          }
        }
      }
      &_text {
        @include text(2rem, layout-grey-30);
        padding: 0 0.75rem;
      }
      &_checkLabel {
        @include text(12px, layout-grey-90);
        padding: 0 0 1rem;
      }
      &_check {
        margin: 0 0 1rem 1rem;
      }
      &_link {
        @include text(0.875rem, darwined-blue-30);
        padding: 0 0 0 1rem;
        cursor: pointer;
      }
      &_radio {
        margin: 0 0 1rem 1rem;
        &:first-of-type {
          margin: 0 0 1rem;
        }
        &.item_radio__block {
          margin: 0 0 1rem 2rem;
        }
      }
      .itemWeek {
        padding: 2rem 0 1.25rem;
        &_label {
          @include text(1rem, layout-grey-90);
          padding: 0 0.5rem 0 0;
        }
        &_input {
          margin: 0 0.5rem 0 0;
        }
        &_week {
          padding: 0.75rem 0 0;
        }
      }
      .textError {
        @include text(12px, message-negative-50);
        padding: 10px 0 0;
      }
      .info_week {
        padding: 0;
        &_text {
          @include text(12px, layout-grey-70);
          .bold {
            @include text(12px, layout-grey-90, medium);
          }
        }
      }
    }
    .cntFooter {
      padding: 3.75rem 0;
      &.cntFooter__weekView {
        padding: 0 2.5rem;
      }
      &_btn {
        margin: 0 0 0 0.5rem;
        &:first-of-type {
          margin: 0;
        }
      }
    }
  }
}

.repeat {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0.75rem 0 0;
  &_radio {
    width: auto !important;
    padding: 0 0 0 1.5rem;
    &:first-of-type {
      padding: 0;
    }
  }
  .item {
    &_text {
      @include text(12px, layout-grey-70);
      .bold {
        @include text(12px, layout-grey-90, medium);
      }
    }
    &_table {
      padding: 0.75rem 0 0;
    }
    .errorBox {
      margin: 0.75rem 0 0;
      &_text {
        @include text(0.75rem, layout-grey-90);
      }
    }
  }

  &__weekly {
    display: flex;
    align-items: center;
    gap: 1rem;
    background: map-get($colors, layout-grey-5);
    border: 1px solid map-get($colors, layout-grey-10);
    padding: 0.534rem 0;
    flex-direction: column;
    border-radius: 4px;
    &__container {
      margin-top: 1rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
    }
    &__label {
      @include text(20px, layout-grey-70, bold);
    }
    &__weeks {
      margin-top: 1rem;
    }
  }
}

@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/mixin";

.modal {
  min-width: 360px;
  max-width: 580px;
  width: auto;
  border-radius: 0.3rem;
  box-shadow: 0 0 10px 0 rgba(map-get($colors, layout-grey-90), 0.25);
  .header {
    border-radius: 0.3rem 0.3rem 0 0;
    min-height: 76px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;

    &_close {
      @include text(map-get($sizeText, 12), foris-white, medium);
      padding: 1rem;
      border: none;
      background-color: transparent;
      display: flex;
      gap: 0.25rem;

      &:hover {
        border: none;
        background-color: transparent;
      }

      &:transparent {
        border: none;
        background-color: transparent;
      }
    }
  }
  .content {
    padding: map-get($spacing, md);

    &_wrapper {
      padding: map-get($spacing, xs);
      margin-bottom: map-get($spacing, lg);
    }

    &_title {
      color: map-get($colors, foris-white) !important;
      padding: 0 map-get($spacing, md) map-get($spacing, md) map-get($spacing, md);
      margin: 0 auto 0 0;
    }
    &_subtitle {
      @include text(14px, layout-grey-90, medium);
      padding-bottom: 0.25rem;
    }
    &_children {
      @include text(14px, layout-grey-90);
    }
    .footer {
      justify-content: flex-start;
      align-self: flex-;
      &_btn {
        margin: 0 0 0 map-get($spacing, sm);
        font-weight: 500;
        min-width: 120px;

        &:first-child {
          margin: 0;
        }
      }
    }
  }

  &.modal__confirm {
    .header {
      background: map-get($colors, darwined-blue-30);
    }
    .content {
      &_title {
        @include text(map-get($sizeText, 22), foris-white);
      }
    }
  }
  &.modal__warning {
    .header {
      background: map-get($colors, message-alert-50);
      &_cntIcon {
        background: map-get($colors, message-alert-50);
      }
    }
    .content {
      &_title {
        @include text(map-get($sizeText, 22), message-alert-50, bold);
        text-align: center;
      }
    }
  }
  &.modal__error {
    .header {
      background: map-get($colors, message-negative-50);
      &_cntIcon {
        background: map-get($colors, message-negative-50);
      }
    }
    .content {
      &_title {
        @include text(map-get($sizeText, 22), message-negative-50, bold);
        text-align: center;
      }
    }
  }
}

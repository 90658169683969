@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/mixin";

.multyCapacityInput {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 0.7rem;

  .label {
    @include text(map-get($sizeText, 14), darwined-blue-10, bold);
    border: none;
    padding: 0;
    &:hover {
      border: none;
      background-color: transparent;
    }
    &:active {
      border: none;
      background-color: transparent;
    }
    &--disabled {
      border: none;
      cursor: default;
      color: map-get($colors, layout-grey-30) !important;
    }
  }

  .icon {
    @include text(map-get($sizeText, 14), darwined-blue-30, bold);
    margin: 0 0.375rem;
    &--disabled {
      color: map-get($colors, layout-grey-30);
    }
  }

  .input {
    display: flex;
    align-items: center;
    width: 57.38px;
    input {
      @include text(map-get($sizeText, 14), layout-grey-40);
    }
  }
}

@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss//legacyBreakpoints";
@import "../../../../../assets/scss/mixin";

.main {
  @include text(map-get($sizeText, 16), layout-grey-90);
  display: flex;
  margin: 0 8.35%;
  padding: map-get($spacing, lg);
  flex-direction: column;
  justify-content: flex-start;
}

.header {
  a {
    align-items: center;
    display: flex;
    justify-content: flex-start;
  }
  &__icon {
    @include text(map-get($sizeText, 22), layout-grey-90, medium);
  }
  &__text {
    @include text(map-get($sizeText, 22), layout-grey-90, medium);
    margin-left: map-get($spacing, md);
  }
}

@media (max-width: map-get($legacyBreakpoints, xl)) {
  .main {
    margin: 0 !important;
  }
}

@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/variables";

.groupStatusIcon {
  color: map-get($colors, message-positive-40);

  &__error {
    color: map-get($colors, layout-grey-50);
  }
}

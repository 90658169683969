@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixin";

.cardDays {
  margin: 0 0 1.5rem;
  .cardItem {
    @include text(14, layout-grey-90, medium);
    padding: 0 0 1rem;
    &_label {
      @include text(12px, layout-grey-90);
      display: block;
      margin: 0 0 0.25rem;
    }
    &_value {
      @include text(14px, layout-grey-70);
    }
    &_days {
      margin: 0 0 0 1rem;
      &:first-child {
        margin: 0;
      }
    }
  }
}

@media (max-width: map-get($breakpoints, md)) {
  .cardDays {
    .cardItem {
      &_days {
        margin: 1rem 0 0 0;
        &:first-child {
          margin: 0;
        }
      }
      &_label {
        margin: 0.75rem 0 0 0;
      }
    }
  }
}

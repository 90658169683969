@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/variables";

.cntDetails {
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: all 0.3s ease;
  &.cntDetails__active {
    height: auto;
    opacity: 1;
    overflow: visible;
  }

  &_content {
    border-bottom: map-get($borders, md) solid var(--color-gray-90);
    padding: 0 0 map-get($spacing, md) 0;
  }

  &_expand {
    column-gap: map-get($spacing, xxl);
    display: flex;
    flex-wrap: wrap;
  }
}

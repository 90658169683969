@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixin";

.dayRowContent {
  margin: 1rem 0 1rem;
  @include text(14px, layout-grey-30, bold);
  margin: 1rem 0 0;
  &:first-of-type {
    margin: 0;
  }
  &:last-of-type {
    .cntForm {
      &.cntForm__border {
        border-bottom: none;
        padding: 0;
      }
    }
  }
  .cntForm {
    max-width: calc(100% - 26px);
    @include flex(flex-start, flex-start);
    &.cntForm__border {
      border-bottom: 1px solid map-get($colors, layout-grey-30);
      padding: 0 0 1rem;
    }
    &_calendar {
      margin: 0 0.5rem 0 0;
    }
    &_time {
      margin: 0 0.25rem 0 0;

      & [class*="select_label"] {
        margin: 0 0 map-get($spacing, xxs) 0;
      }

      &__endDate {
        margin: 0 0.25rem;
      }
    }
    &_selectTime {
      margin: 0 1rem 0 0;
    }
    &_separator {
      margin: 1.5rem 0 0;
    }

    &_warning {
      display: flex;
      align-items: flex-end;
      padding: 0 0 0.5rem 0;
      color: map-get($colors, message-alert-70);
    }
  }
  &_remove {
    @include box(transparent, 50%, layout-grey-30);
    @include flex(center, center);
    width: 18px;
    height: 18px;
    margin: 0.9875rem 0 0 0.5rem;
    cursor: pointer;
    outline: none;
    .iconRemove {
      color: map-get($colors, layout-grey-30);
    }
  }
}

@media (max-width: map-get($breakpoints, xs)) {
  .dayRowContent {
    .cntForm {
      &_calendar {
        margin: 0 0.5rem 0.75rem 0;
      }
      &_select {
        @include flex(space-between, center);
      }
    }
    &_remove {
      margin: 1.625rem 0 0 0.5rem;
    }
  }
}

@import "../../../assets/scss/avocadoVariables";
@import "./../../../assets/scss/mixin";
@import "./../../../assets/scss/variables";

.mainMenu {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &__large {
    .mainMenu_products {
      min-width: 500px;
      max-width: unset;
    }
  }

  &_trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &_icon {
    color: map-get($colors, layout-grey-50);
    display: flex;
  }

  &_products {
    cursor: default;
    display: none;
    flex-direction: column;
    gap: map-get($spacing, lg);
    position: absolute;
    top: 100%;
    right: 0.25rem;
    min-width: -webkit-min-content;
    min-width: min-content;
    z-index: 1900;
    border: 1px solid #d4d4d5;
    line-height: 1.4285em;
    max-width: 250px;
    background: #fff;
    font-weight: 400;
    font-style: normal;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0.28571429rem;
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
    margin: 0.71428571rem 0 0;
    padding: map-get($spacing, md);

    &__active {
      display: flex;
    }

    &::before {
      position: absolute;
      content: "";
      width: 0.71428571rem;
      height: 0.71428571rem;
      background: #fff;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      z-index: 2;
      top: -0.30714286em;
      right: 1em;
      bottom: auto;
      left: auto;
      margin-left: 0;
      box-shadow: -1px -1px 0 0 #bababc;
    }

    .topModules {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: map-get($spacing, md);
    }

    .module {
      min-width: 140px;

      &_title {
        font-size: map-get($sizeText, 12);
        font-weight: map-get($fonts, bold);
        line-height: map-get($sizeText, 14);
        color: map-get($colors, layout-grey-70);
        margin: 0 0 map-get($spacing, sm) 0;
      }

      &_list {
        padding: map-get($spacing, sm);
        border-radius: 0.5rem;
        background-color: var(--color-neutral-05);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: map-get($spacing, xs);

        .listItem {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          text-align: left;
          gap: map-get($spacing, xs);
          padding: 0 map-get($spacing, xs);

          &_logo {
            object-fit: contain;
          }

          &_name {
            font-size: map-get($sizeText, 14);
            line-height: map-get($sizeText, 16);
            color: map-get($colors, layout-grey-90);
          }
        }
      }
    }
  }
}

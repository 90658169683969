@import "../../../assets/scss/mixin";
@import "../../../assets/scss/variables";

.cntHeader {
  background: var(--color-gray-80);

  .dimensionWrapper {
    width: auto;
    padding: 0 map-get($spacing, md) 0 0;
    margin: 0 map-get($spacing, md) 0 0;
    max-width: 172px;
    border-right: map-get($borders, md) solid var(--color-gray-30);

    &_title {
      color: var(--color-gray-30);
      margin: 0 0 map-get($spacing, xs);
    }

    &_name {
      color: var(--color-gray-30);
    }
  }

  &_breadcrumbContent {
    padding: map-get($spacing, xl) 0 0 map-get($spacing, xxxl);
  }

  &_content {
    display: flex;
    width: 100%;
    padding: map-get($spacing, xl) 0 map-get($spacing, xl) map-get($spacing, xxxl);

    &.cntHeader_content__divided {
      border-bottom: map-get($borders, md) solid var(--color-gray-90);
    }

    .menuWrapper {
      display: flex;
      align-items: center;
      padding: map-get($spacing, xl) map-get($spacing, xxxl) map-get($spacing, xl) 0;
    }
  }

  .headerIcon {
    width: 50px;
    margin: 0 1rem 0 0;
  }
  .infoItem {
    flex: 1;
    padding: 0;

    &_container {
      display: flex;
      gap: map-get($spacing, xs);
      margin: map-get($spacing, md) 0 0;
    }

    &_topTitle {
      @include text(0.875rem, foris-white);
      text-transform: uppercase;
      margin: 0 0 map-get($spacing, xs);

      &_lower {
        text-transform: none;
      }
    }

    &_title {
      @include flex(flex-start, center);
      padding: 0 map-get($spacing, xxxl) 0 0;

      .icon {
        margin: 0 0 0 map-get($spacing, xs);
      }
    }

    &_subTitleWrapper {
      display: flex;
      align-items: center;
      gap: 0.75rem;
    }

    &_subTitle {
      @include flex(flex-start, center);
      gap: map-get($spacing, xs);

      .regular {
        font-weight: map-get($fonts, regular);
        padding: 0 0 0 0.125rem;
      }

      .link {
        color: var(--color-gray-05);
        display: flex;
        align-items: center;

        svg > path {
          fill: var(--color-gray-05);
        }

        &.link__spacing {
          padding: 0 0 0.125rem 0.25rem;
        }

        &_text {
          text-decoration: underline;
        }

        &_icon {
          margin: 0 0 0.125rem 0.25rem;
          text-decoration: none;
        }
      }
    }
  }

  .detailsAnimate {
    transition: max-height 0.5s ease-in-out;
    overflow: hidden;
    max-height: 0;
    width: 100%;

    &.detailsAnimate__active {
      max-height: 350px;
    }
  }

  .detailsWrapper {
    padding: map-get($spacing, md) map-get($spacing, xxxl);
    width: 100%;
  }

  .linkTo {
    padding: map-get($spacing, sm) map-get($spacing, xxxl);
    border-top: map-get($borders, md) solid var(--color-gray-90);
    transition: background-color 0.25s;

    &_btn {
      @include flex(flex-start, center);
      background: none;
      cursor: pointer;
      gap: map-get($spacing, xxs);
      width: 100%;

      .title {
        color: var(--color-gray-30);
        font-weight: map-get($fonts, medium);
      }

      &.linkTo_btn__active {
        .icon {
          transform: rotate(180deg);
        }
      }
      .icon {
        color: var(--color-gray-30);
        transition: all 0.25s;
      }
    }
  }

  .headerSubtitle {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &_label {
      color: map-get($colors, foris-white);
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;

      .isRegular {
        font-weight: 400;
      }
    }
  }
}

@import "../../../assets/scss/avocadoVariables";

.dropdownBox {
  &.dropdownBox {
    min-height: unset;
    max-height: 60px;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
  }

  &.dropdownBox__isOpen {
    max-height: 500px;
    .dropdownBox_head {
      .headIcon {
        transform: rotate(-180deg);
      }
    }
  }

  &_head {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: map-get($spacing, xxs);
    background-color: transparent;
    outline: none;
    cursor: pointer;

    .headIcon {
      transition: transform 0.5s ease-in-out;
      transform: rotate(0);
    }
  }

  &_content {
    margin: map-get($spacing, xl) 0 0;
  }
}

@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/avocadoVariables";

.cntCreate {
  position: relative;
  .content {
    padding: 32px;
    max-width: 1030px;

    &_groupFormActivator {
      margin: map-get($spacing, xl) 0 0;
    }

    &_actions {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: map-get($spacing, sm);
      margin: map-get($spacing, xl) 0 0;

      .cancelBtn {
        min-width: 120px;
      }
    }
  }
}

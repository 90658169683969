@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixin";

.cntRequest {
  background: map-get($colors, layout-grey-5);
  min-height: calc(100vh - 50px);
  .detailRequest {
    padding: 1rem;
    .footerRequest {
      margin: 1.5rem 0 0;
      &_btn {
        margin: 0 0 0 0.75rem;
        &:first-child {
          margin: 0;
        }
      }
    }
  }
}

@media (max-width: map-get($breakpoints, xs)) {
  .cntRequest {
    .header {
      padding: 1.5rem 1.75rem;
    }
    .detailRequest {
      padding: 1.5rem 1.75rem;
      .footerRequest {
        &_btn {
          margin: 0.75rem 0 0 0;
          &:first-child {
            margin: 0;
          }
        }
      }
    }
  }
}

@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/variables";

.resultsHeader {
  border-bottom: 1px solid map-get($colors, layout-grey-30);
  padding: 0 0 0.4375rem;
  &_title {
    @include text(1.375rem, layout-grey-90);
  }
  .buttons {
    display: flex;
    &_item {
      margin: 0 0 0 1.5rem;
      &:first-of-type {
        margin: 0;
      }
    }
  }
}

@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/variables";

.formHeader {
  border-bottom: 1px solid map-get($colors, layout-grey-30);
  padding: 0 0 0.4375rem;
  display: flex;
  min-width: 550px;
  max-width: calc((100 / 12) * 7%);

  &_title {
    @include text(1.375rem, layout-grey-90);
  }
  &_blocksInfo {
    display: flex;
    justify-content: space-around;
  }
  &_separator {
    min-width: 10px;
    margin-left: 10px;
    color: map-get($colors, layout-grey-50);
  }
  .buttons {
    display: flex;
    &_item {
      margin: 0 0 0 1.5rem;
      &:first-of-type {
        margin: 0;
      }
    }
  }

  &_card {
    margin-top: 1rem;
    &_title {
      color: map-get($colors, layout-black) !important;
    }
  }
}

@import "../../../assets/scss/mixin";
@import "../../../assets/scss/avocadoVariables";

.cntAutocomplete {
  // @include box(foris-white, 0.25rem, layout-grey-30);
  padding: 0;
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;

  &_input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    position: relative;
    padding: 0 2.875rem 0 map-get($spacing, xs);
    border: 1px solid var(--color-neutral-30);
    border-radius: map-get($radius, md);
    background-color: var(--color-neutral-00);
    outline: none;

    &.cntAutocomplete_input__withFilter {
      border-radius: 0 map-get($radius, md) map-get($radius, md) 0;
    }

    &.cntAutocomplete_input__disabled {
      background: var(--color-neutral-05);
    }

    .inputAutocomplete {
      font-size: map-get($sizeText, 14);
      font-style: normal;
      font-weight: map-get($fonts, regular);
      line-height: map-get($sizeText, 18);
      color: var(--color-neutral-90);
      background-color: transparent;

      width: 100%;
      padding: 0;
      border: none;
      outline: none;

      &::placeholder {
        color: var(--color-neutral-50);
      }
    }

    .listSuggest {
      background-color: var(--color-neutral-00);
      border-radius: map-get($radius, md);
      box-shadow: var(--shadow-elevate);
      padding: map-get($spacing, xs);
      display: flex;
      flex-direction: column;
      gap: map-get($spacing, sm);
      position: absolute;
      top: calc(100% + 4px);
      left: 0;
      right: 0;
      margin: 0;
      list-style: none;
      z-index: map-get($zIndex, select);

      &_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: map-get($spacing, xs) map-get($spacing, sm);
        border-radius: 0.5rem;
        background-color: transparent;
        transition: background-color 0.25s ease-in-out;
        cursor: pointer;

        &:hover {
          background-color: var(--color-neutral-30-20);
        }

        &.listSuggest_item__separator {
          background-color: var(--color-neutral-10);
          height: 1px;
          padding: 0;
          width: 100%;
          border-radius: 0;

          &:hover {
            background-color: var(--color-neutral-10);
          }
        }

        &.listSuggest_item__empty {
          cursor: initial;

          &:hover {
            background-color: transparent;
          }
        }

        .textSuggest {
          font-size: map-get($sizeText, 14);
          font-style: normal;
          font-weight: map-get($fonts, regular);
          line-height: map-get($sizeText, 18);
          color: var(--color-neutral-70);
          flex: 1;
          margin: 0;
        }
      }
    }
  }

  &_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -0.5px;
    background-color: var(--color-neutral-70);
    border-radius: 0 map-get($radius, md) map-get($radius, md) 0;
    border: none;
    outline: none;
    cursor: pointer;
  }
}

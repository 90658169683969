@import "../../../assets/scss/mixin";
@import "../../../assets/scss/avocadoVariables";
@import "../../../assets/scss/variables";

.groupMigration {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: calc(100vh - 50px);
  display: block;
  background: map-get($colors, foris-white);

  &_content {
    max-width: 470px;
    padding: map-get($spacing, lg);

    .contentTitle {
      font-weight: map-get($fonts, regular);
      margin: 0 0 map-get($spacing, lg);
    }

    .contentActions {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: map-get($spacing, sm);
      margin: map-get($spacing, lg) 0 0;
    }
  }

  .warningCard {
    &:first-of-type {
      margin-top: map-get($spacing, lg);
    }

    &:not(:last-of-type) {
      margin-bottom: map-get($spacing, md);
    }

    &_action {
      font-size: map-get($sizeText, 12);
      min-height: unset;
      padding: 0 map-get($spacing, xxs);
      margin: map-get($spacing, xxs) 0 0 -4px;
    }
  }
}

@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/variables";

.cnt_recommendations {
  @include box(foris-white, 0.25rem, layout-grey-10);
  padding: 1rem;
  &-title {
    @include text(1rem, darwined-blue-30, bold);
    margin: 0;
    // padding: 0 0 1rem;
  }
  .item_recommendations {
    padding: 0.5rem 0 0 0.5rem;
    &-label {
      @include text(12px, darwined-blue-50, medium);
      width: 200px;
      display: flex;
      justify-content: space-between;
    }
    &-info {
      @include text(12px, layout-grey-50);
      padding: 0 0 0 0.5rem;
    }
    .list_info_recommendations {
      margin: 0;
      padding: 0 0 0 1rem;
      &-item {
        @include text(10px, layout-grey-70);
      }
    }
    .list_recommendations {
      margin: 0 0 0 0.5rem;
      padding: 0.5rem 1rem 0.5rem 0;
      border-bottom: 1px solid map-get($colors, layout-grey-10);
      &:first-child {
        padding: 0 1rem 0.5rem 0;
      }
      &:last-child {
        border-bottom: 0;
      }
      &-item {
        @include text(10px, layout-grey-50);
        align-items: center;
        .label_list_recommendations {
          @include text(10px, layout-grey-70, medium);
          width: 100px;
          display: flex;
          justify-content: space-between;
          margin: 0 0.5rem 0 0;
        }
      }
    }
  }
}

@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/variables";

.headerMenu {
  position: relative;
  z-index: 10;

  .btnMenu {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;

    &_icon {
      color: map-get($colors, foris-white);
      font-size: 2rem;
    }
  }
  .cntMenu {
    @include box(foris-white, 4px);
    min-width: 200px;
    padding: 0.5rem;
    position: absolute;
    right: 0;
    margin: 0.5rem 0 0 0;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);

    &_divider {
      height: 1px;
      width: 100%;
      background-color: map-get($colors, layout-grey-30);
      margin: 0.25rem 0;
    }

    &_item {
      @include text(0.875rem, layout-grey-90, medium);
      padding: 0.5rem;
      cursor: pointer;
      outline: none;
      gap: 4px;
      background-color: transparent;
      display: flex;
      align-items: center;
      text-align: left;
      flex-wrap: nowrap;

      &.cntMenu_item__disabled {
        color: map-get($colors, layout-grey-50);

        .iconItem {
          color: map-get($colors, layout-grey-50);
        }
      }

      .iconItem {
        font-size: 1rem;
        color: map-get($colors, layout-grey-90);
      }
    }
  }
}

@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixin";

.boxSession {
  @include box(darwined-blue-30, 0.25rem);
  @include flex(flex-start, center);
  min-height: 36px;
  display: inline-flex;
  position: relative;
  cursor: pointer;
  overflow-x: hidden;
  &:hover {
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);
  }
  &:active {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
    background: darken(map-get($colors, darwined-blue-30), 5%);
  }

  //palette color
  @each $key, $value in $colorsPalette {
    &.boxSession__#{$key} {
      background: map-get($colorsPalette, $key);
      &:active {
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
        background: darken(map-get($colorsPalette, $key), 5%);
        .boxSession_btnRemove {
          background: darken(map-get($colorsPalette, $key), 5%);
        }
      }
      .boxSession_btnRemove {
        background: map-get($colorsPalette, $key);
        &:hover {
          background: darken(map-get($colorsPalette, $key), 2.5%);
        }
      }
    }
  }

  //disabled type
  &.boxSession__disabled,
  &.boxSession__deleted {
    background: map-get($colors, layout-grey-30);
    .boxSession_content {
      color: map-get($colors, foris-white);
    }
  }

  //inverse type
  &.boxSession__inverse {
    background: map-get($colors, foris-white);
    .boxSession_content {
      color: map-get($colors, foris-blue-50);
    }
    .boxSession_btnRemove {
      background: map-get($colors, foris-white);
      border-left: 1px solid map-get($colors, foris-blue-50);
      .icon {
        color: map-get($colors, foris-blue-50);
      }
    }
    @each $key, $value in $colorsPalette {
      &.boxSession__#{$key} {
        border: 1px solid map-get($colorsPalette, $key);
        &:active {
          .boxSession_content {
            color: map-get($colors, foris-white);
          }
          .boxSession_btnRemove {
            color: map-get($colors, foris-white);
            border-color: map-get($colors, foris-white);
            .icon {
              color: map-get($colors, foris-white);
            }
          }
        }
        .boxSession_content {
          color: map-get($colorsPalette, $key);
        }
        .boxSession_btnRemove {
          border-left: 1px solid map-get($colorsPalette, $key);
          .icon {
            color: map-get($colorsPalette, $key);
          }
          &:hover {
            background: map-get($colors, foris-white);
            border-radius: 0 0.25rem 0.25rem 0;
          }
        }
      }
    }
  }
  &_content {
    @include text(0.625rem, foris-white);
    padding: 0.25rem 0.75rem;
    position: relative;
    &.boxSession_content__full {
      width: 100%;
    }
  }

  &_btnRemove {
    @include flex(center, center);
    width: 36px;
    height: 100%;
    min-height: 34px;
    background: map-get($colors, foris-blue-50);
    border-left: 1px solid map-get($colors, foris-white);
    border-radius: 0 0.25rem 0.25rem 0;
    outline: none;
    cursor: pointer;
    position: absolute;
    right: 0;
    transition: all 0.35s cubic-bezier(0.31, -0.105, 0.43, 1.4);
    transition: background 0.35s ease;
    .icon {
      color: map-get($colors, foris-white);
    }
    .textRemove {
      display: none;
      @include text(0.625rem, foris-white);
      padding: 0 0 0 0.25rem;
    }
    &:hover {
      // width: 100%;
      // padding: 0 0.625rem;
      border-left: 0;
      border-radius: 0.25rem;
      // .textRemove {
      //   display: block;
      // }
    }
  }
}

@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/variables";

.loading {
  z-index: 999;
}

.cntChangeComponent {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 133px);
  position: absolute;
  top: 50px;
  bottom: 0;
  background: var(--color-neutral-00);
  z-index: map-get($zIndex, modal);

  .content {
    padding: map-get($spacing, xxl) map-get($spacing, xxxl);

    &_selectContent {
      display: flex;
      flex-direction: column;
      width: 100%;

      .title {
        margin: 0 0 map-get($spacing, xl);
      }

      .notification {
        margin: map-get($spacing, sm) 0 0;
        width: 582px;
      }
    }

    &_actionButton {
      display: flex;
      gap: map-get($spacing, sm);
    }
  }
}

@import "./../../../../assets/scss/variables";
@import "./../../../../assets/scss/mixin";

.topTitle {
  @include text(0.875rem, foris-white);
  text-transform: uppercase;
  padding: 0 0 0.25rem;
  &_lower {
    text-transform: none;
  }
}

.title {
  @include flex(flex-start, center);
  @include text(1.25rem, foris-white, bold);
  &_icon {
    margin: 0 0 0 0.75rem;
  }
}

.subTitle {
  @include text(0.75rem, foris-white, medium);
  &_regular {
    font-weight: map-get($fonts, regular);
  }
  &_bold {
    @include text(0.75rem, foris-white, medium);
    text-decoration: underline;
  }
}

.labelsWrapper {
  &__bordered {
    border-bottom: map-get($borders, md) solid var(--color-gray-90);
    padding: 0 0 0.75rem 0;
  }
}

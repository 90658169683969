@import "../../../assets/scss/mixin";
@import "../../../assets/scss/avocadoVariables";

.cntResults {
  background: var(--color-neutral-00);

  .searchResults {
    &_header {
      height: 100%;
      display: flex;
      align-items: flex-end;

      [class*="_skeletonBase"] {
        background-color: var(--color-neutral-70);
      }
    }
  }

  &_table {
    padding: map-get($spacing, xxl) map-get($spacing, xxxl);
  }

  &_pagerContainer {
    justify-content: end !important;
    margin: map-get($spacing, md) 0 0;
  }

  .header {
    display: flex;
    justify-content: flex-end;
    padding: 0 0 map-get($spacing, xl);
  }
}

.cntResultsSkeleton {
  &_paginationBtn {
    display: flex;
    gap: map-get($spacing, xxs);
    justify-content: flex-end;
    margin: map-get($spacing, md) 0 0;
  }

  &_header {
    display: flex;
    justify-content: flex-end;
    margin: 0 0 map-get($spacing, xl);
  }

  &_table {
    border: map-get($borders, md) solid var(--color-neutral-30);
    border-radius: map-get($radius, lg);

    .header {
      padding: 10px 14px;
      border-bottom: map-get($borders, md) solid var(--color-neutral-30);
    }

    .columns {
      display: flex;
      &:not(:last-child) {
        border-bottom: map-get($borders, md) solid var(--color-neutral-30);
      }

      &_item {
        padding: 10px 14px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

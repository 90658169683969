@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixin";

.table {
  margin-top: 1rem;
  background-color: map-get($colors, layout-grey-10);
  padding: 0.75rem;
  border-color: transparent;
  border-radius: 0.25rem;

  &_cell {
    @include text(0.75rem, layout-grey-70);

    &_left_actions {
      @include flex(flex-start, center);
    }

    &_code {
      @include text(0.75rem, darwined-blue-30, medium);
    }
  }

  .alert_icon,
  .is_new_icon,
  .trash_icon,
  .checkIcon {
    margin-right: 0.25rem;
  }

  .checkIcon {
    color: map-get($colors, message-positive-50);
  }
  .alert_icon {
    color: map-get($colors, message-alert-50);
  }
  .is_new_icon {
    color: map-get($colors, darwined-blue-50);
  }
  .trash_icon {
    color: map-get($colors, darwined-grey-20);
  }
  .delete_icon {
    span {
      color: map-get($colors, message-negative-30);
    }
  }
}

@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/mixin";

.main {
  @include text(0.7rem, layout-grey-90);
  padding: map-get($spacing, lg);
  align-items: center;
  width: 100vw;
  max-width: 100%;
  margin: 0;
}

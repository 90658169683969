@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixin";

.modalBookingDetail {
  &_title {
    @include text(18, foris-white, bold);
  }
  .bookingDetailInfo {
    padding: 1.5rem 1rem;
    &_text {
      @include text(16, layout-grey-90);
      padding: 0 0 1rem;
    }
    &_btn {
      margin: 0 0 0 0.75rem;
      &:first-child {
        margin: 0;
      }
    }
  }
}

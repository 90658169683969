@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/variables";

.listLabels {
  margin: map-get($spacing, sm) 0 0;
  min-height: auto;
  padding: map-get($spacing, md);

  &_header {
    align-items: center;
    display: flex;
    gap: map-get($spacing, md);
    margin: 0 0 map-get($spacing, xl);
  }

  &_contentTags {
    display: flex;
    flex-wrap: wrap;
    gap: map-get($spacing, xs);
  }
}

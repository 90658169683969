@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/mixin";

.errors {
  margin-bottom: map-get($spacing, md);
  h3 {
    font-size: map-get($sizeText, 16) !important;
    color: map-get($colors, message-negative-50);
  }
}

.groups {
  margin-top: map-get($spacing, md);
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.error {
  &_title {
    @include text(map-get($sizeText, 14), layout-black, bold);
  }
  &_message {
    @include text(map-get($sizeText, 14), layout-black);
  }
}

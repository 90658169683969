@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/variables";

.cntDisabledSession {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 133px);
  position: absolute;
  top: 50px;
  bottom: 0;
  background: var(--color-neutral-00);

  .content {
    padding: map-get($spacing, xxl) map-get($spacing, xxxl);

    &_title {
      margin: 0 0 map-get($spacing, xs);
    }
  }
  .cntOption {
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, xs);
    margin: 0 0 map-get($spacing, xl);
  }
  .actionsButton {
    display: flex;
    gap: map-get($spacing, sm);
    margin: map-get($spacing, xxl) 0 0;
  }
}

@import "../../../assets/scss/avocadoVariables";
@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixin";

.modalContent {
  @include text(0.875rem, layout-grey-90);
  width: 100%;
  position: relative;
  top: 0;
  min-width: 600px;

  &_header {
    @include text(1rem, foris-white, bold);
    display: grid;
    grid-template-columns: 1fr;
    gap: map-get($spacing, md);
    background: map-get($colors, darwined-blue-50);
    padding: map-get($spacing, md) map-get($spacing, md) map-get($spacing, md) map-get($spacing, lg);
    border-radius: 0.5rem 0.5rem 0 0;

    .headerTitle {
      > * {
        display: flex;
        flex-direction: column;
      }
      span:not(.headerTitle_customTitle) {
        padding: 0.25rem 0 0 0;
        &:first-child {
          @include text(0.875rem, foris-white);
        }
      }

      &_customTitle {
        font-size: map-get($sizeText, 22);
        font-weight: map-get($fonts, medium);
        padding: 0;
      }
    }

    .closeIcon {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: map-get($spacing, xxs);
      cursor: pointer;
      background-color: transparent;

      &_text {
        line-height: 1;
      }
    }
  }

  &_detail {
    background: map-get($colors, foris-white);
    border-radius: 0 0 0.5rem 0.5rem;
  }
}

@media (min-width: $md) {
  .modalContent {
    width: 575px;
    height: auto;
  }
}

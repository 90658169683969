@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/legacyBreakpoints";
@import "../../../../../assets/scss/mixin";

.main {
  @include text(0.7rem, layout-grey-90);
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: map-get($spacing, lg);
  width: 100vw;
  max-width: 100%;
}

.tabs {
  margin-top: 1.4rem;
  width: 83.3%;
}

@media (max-width: map-get($legacyBreakpoints, xl)) {
  .tabs {
    width: 100% !important;
  }
}

@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixin";

.header {
  padding: 1.125rem 1rem 1.75rem;
  background: map-get($colors, darwined-blue-30);
  &_link {
    @include flex(flex-start, center);
    @include text(24px, foris-white);
    padding: 0 0 0.75rem;
    .iconBack {
      margin: 0 0.5rem 0 0;
    }
  }
  .item {
    margin: 1rem 0 0 0;
    &_label {
      @include text(10px, foris-white, bold);
    }
    &_info {
      @include text(14px, foris-white);
    }
  }
}

@media (min-width: map-get($breakpoints, md )) {
  .header {
    padding: 1.5rem 1.75rem;
  }
}

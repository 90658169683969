@import "../../../assets/scss/avocadoVariables";
@import "../../../assets/scss/mixin";
@import "../../../assets/scss/variables";

.shared_weeks {
  @include box(foris-white, 0);
  display: flex;
  flex-direction: row;
  padding: 0 0 map-get($spacing, md);
  margin: 0 0 map-get($spacing, lg);
  border-bottom: 1px solid map-get($colors, layout-grey-30);

  &_title {
    font-size: map-get($sizeText, 14);
    color: #3b4249;
    font-weight: map-get($fonts, medium);
    padding: 0 0 map-get($spacing, md);
  }

  &_range {
    margin: map-get($spacing, xl) map-get($spacing, xl) map-get($spacing, xs);
    padding: 0 map-get($spacing, xs);
  }
}

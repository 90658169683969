@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/variables";

.tabs {
  padding: 1.5rem 0 0;
}
.table {
  margin: 0.75rem 0 0;
  .numberWeek {
    @include text(14px, layout-grey-90, bold);

    &_date {
      font-weight: 400;
    }
  }

  .weekHoliday {
    @include text(14px, layout-grey-70, 400);

    &_title {
      font-weight: 500;
    }
  }

  .validationRow {
    &:not(:last-child) {
      margin-bottom: 0.25rem;
    }
  }

  .incidenceValidation {
    &_session {
      @include text(12px, layout-grey-50);
      padding: 0 0 0.25rem 0.5rem;
    }
  }
}

.successCard {
  margin: 1.5rem 0 0;
}

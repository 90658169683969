@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/variables";
@import "../../../../../assets/scss/mixin";

.cntSearchClassRoom {
  padding: 1.75rem 2rem;
  &_link {
    @include flex(flex-start, center);
    @include text(24px, layout-grey-90);
    padding: 0 0 1rem;
    .iconBack {
      margin: 0 0.625rem 0 0;
    }
  }
  &_required {
    @include text(12px, layout-grey-50);
    margin: 0.75rem 0 0;
  }
  .cardSearchClassRoom {
    &:first-child {
      margin: 0;
    }
  }

  .cardContent {
    padding: 0;
    .cardBlock {
      padding: 1.5rem;
      border-bottom: 1px solid map-get($colors, layout-grey-10);
      &_text {
        @include text(14px, layout-grey-70, medium);
        padding: 0 0 1rem;
      }
    }
    &_required {
      @include text(12px, layout-grey-50);
      padding: 1rem 1.5rem;
    }
  }

  .cardError {
    margin-top: 1.5rem;

    &_text {
      @include text(14px, layout-grey-70);
    }
  }

  .cardWarning {
    margin-top: 1rem;

    &_title > * {
      color: map-get($colors, layout-grey-90);
    }

    &_text {
      @include text(0.75rem, layout-grey-90);
    }
  }

  .footerSearch {
    margin: 1.5rem 0 0;
    &_btn {
      margin: 0 0 0 0.75rem;
      &:first-child {
        margin: 0;
      }
    }
  }
}

.selectorOption {
  display: flex;
  flex-direction: column;

  &_title {
    font-weight: map-get($fonts, light);
    color: map-get($colors, layout-grey-70);
    font-size: map-get($sizeText, 12);
  }

  &_description {
    font-weight: map-get($fonts, regular);
    color: map-get($colors, layout-grey-50);
    font-size: map-get($sizeText, 10);
  }
}

@media (max-width: map-get($breakpoints, xs)) {
  .cntSearchClassRoom {
    padding: 1.75rem 1rem;
    .cardContent {
      .cardBlock {
        padding: 1rem;
      }
      &_required {
        padding: 1rem;
      }
    }
    .footerSearch {
      &_btn {
        margin: 0.75rem 0 0;
        &:first-child {
          margin: 0;
        }
      }
    }
  }
}

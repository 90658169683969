@import "./mixin";
@import "./variables";

.table_wrapper {
  padding: 3rem;
  &-title {
    @include text(26px, layout-grey-70);
    margin: 0;
    padding: 0 0 8px;
  }
  &-link {
    @include text(14px, darwined-blue-30);
    align-items: flex-end;
  }
  & > h1 {
    @extend .table_wrapper-title;
  }
}
.table_repeat {
  width: 350px !important;
  margin: 0.75rem 0 0 !important;
  overflow-x: hidden !important;
  .rt-table {
    overflow-x: hidden !important;
  }
  .rt-tbody {
    .rt-tr-group {
      padding: 0.5rem 0.75rem !important;
    }
  }
}

.table_second {
  width: 350px !important;
  margin: 1rem 0 0 !important;
  overflow-x: hidden !important;
  &.ReactTable {
    border: 1px solid map-get($colors, layout-grey-10);
    border-radius: 0.25rem;
  }
  .rt-thead {
    padding: 0.5rem 0.75rem;
    background: map-get($colors, foris-white);
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom: 1px solid map-get($colors, layout-grey-10);
    .rt-tr {
      .rt-th {
        padding: 0;
        border-right: none;
        .rt-resizable-header-content {
          @include text(12px, darwined-blue-30, bold);
          text-align: left;
        }
      }
    }
  }
  .rt-table {
    overflow-x: hidden !important;
  }
  .rt-tbody {
    @include box(foris-white, 0.25rem);
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    .rt-tr-group {
      padding: 0.5rem 0.75rem !important;
      &:hover {
        background: rgba(map-get($colors, layout-grey-10), 0.7);
      }
      .rt-tr {
        &:hover {
          background: none !important;
        }
        .rt-td {
          @include text(1rem, layout-grey-90);
          padding: 0;
          text-align: left;
          border-right: none;
          .code {
            @include text(10px, layout-grey-70, medium);
          }
          .info {
            @include text(10px, layout-grey-50);
          }
          .btn_delete {
            border: none;
            background: transparent;
            outline: none;
            cursor: pointer;
            padding: 0 1rem;
            &-icon {
              color: map-get($colors, darwined-blue-30);
            }
          }
        }
      }
    }
  }
}
.cnt_table {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
  &.cnt_table--margin {
    margin: 34px 0 0;
  }
  &.ReactTable {
    border: none;
    border-radius: 0.25rem;
  }
  .rt-thead {
    padding: 0.75rem 1rem;
    background: map-get($colors, darwined-blue-30);
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    .rt-tr {
      .rt-th {
        padding: 0;
        border-right: none;
        .rt-resizable-header-content {
          @include text(1rem, foris-white, bold);
          text-align: left;
        }
      }
    }
  }
  .rt-tbody {
    @include box(foris-white, 0.25rem, layout-grey-10);
    border-top: none;
    border-top-left-radius: none;
    border-top-right-radius: none;
    .rt-tr-group {
      padding: 0.75rem 1rem;
      border-bottom: 1px solid map-get($colors, layout-grey-10) !important;
      &:hover {
        background: rgba(map-get($colors, layout-grey-10), 0.7);
      }
      .rt-tr {
        &:hover {
          background: none !important;
        }
        .rt-td {
          @include text(1rem, layout-grey-90);
          padding: 0;
          text-align: left;
          border-right: none;
          .numberWeek {
            @include text(1rem, layout-grey-90, bold);
          }
          .rangeWeek {
            @include text(1rem, layout-grey-50);
          }
          .numberWeekRepeat {
            @include text(12px, layout-grey-90, bold);
          }
          .rangeWeekRepeat {
            @include text(12px, layout-grey-50);
          }
          .linkDetail {
            @include text(1rem, darwined-blue-30);
            cursor: pointer;
          }
          .incidenceValidation {
            @include text(1rem, layout-grey-90);
            &.incidenceValidation__sesion {
              @include text(12px, layout-grey-50);
              padding: 0 0 0 0.5rem;
            }
          }
        }
      }
    }
  }
  .rt-noData {
    display: none !important;
  }
  .pagination-bottom {
    .-pagination {
      border-top: 0;
      box-shadow: none;
      padding: 0;
      justify-content: center;
      .-center {
        flex: 0.5;
        .-pageInfo {
          @include text(12px, layout-grey-50);
          .-pageJump {
            & > input {
              @include text(1rem, layout-grey-90, medium);
            }
          }
        }
      }
      .-previous,
      .-next {
        width: 100px;
        flex: 0;
      }
      .-btn {
        width: 100px;
        background: none;
        font-size: 12px;
        font-weight: map-get($fonts, medium);
        text-transform: uppercase;
      }
    }
  }
}

.loading_wrapper {
  text-align: center;
  padding: 3em;
}

@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/legacyBreakpoints";
@import "../../../../../assets/scss/mixin";

.main {
  margin: 0.875rem 0 0 0;
  width: 83.3%;
  border: 1px solid map-get($colors, layout-grey-10);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);

  .groupers {
    border-radius: 0.5rem;
    background-color: map-get($colors, layout-white);
    box-shadow: var(--shadow-sm);
    display: flex;
    flex-direction: column;
    max-height: 32px;
    overflow: hidden;
    transition: max-height 0.25s;

    &.groupers__open {
      max-height: 500px;
    }

    &_header {
      padding: map-get($spacing, xs) map-get($spacing, md);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: map-get($spacing, sm);
      color: map-get($colors, layout-grey-90);
      font-size: map-get($sizeText, 14);
      font-style: normal;
      font-weight: map-get($fonts, medium);
      line-height: map-get($sizeText, 14);

      .groupersTrigger {
        background-color: transparent;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: transform 0.25s;

        &__open {
          transform: rotate(180deg);
        }
      }
    }

    &_content {
      padding: map-get($spacing, md);

      .groupersList {
        padding: map-get($spacing, md) 0;
        margin: map-get($spacing, md) 0 0 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 1.25rem;

        &_checkbox {
          width: auto;
        }
      }

      .groupersFooter {
        padding: map-get($spacing, md) 0 0 0;
        border-top: 1px solid map-get($colors, layout-grey-10);

        &_action {
          padding: 0;
          min-height: unset;

          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}

.card {
  display: flex;
  background-color: map-get($colors, foris-white);
  &__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    &__title {
      @include text(map-get($sizeText, 16), layout-grey-90, bold);
      margin-bottom: 0.5rem;
    }
    &__subtitle {
      @include text(map-get($sizeText, 14), layout-grey-90);
      padding: map-get($spacing, md) 0;
    }
  }

  &_campuses {
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, md);
    margin: map-get($spacing, lg) 0 0 0;
    padding: 0 map-get($spacing, sm);

    .campusItem {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: map-get($spacing, sm);

      &_checkbox {
        width: auto;
        margin: 0;
      }

      &_groupers {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: map-get($spacing, xs);
      }
    }
  }
  &__bottom {
    display: flex;
    flex-direction: column;
    border-top: 1px solid map-get($colors, layout-grey-10);
    padding: map-get($spacing, lg) 0 0 0;
    margin: map-get($spacing, lg) 0 0 0;

    .bottomCounts {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: map-get($spacing, xs);
      margin: 0 0 map-get($spacing, lg) 0;

      &_label {
        @include text(map-get($sizeText, 14), layout-grey-90);

        .labelCount {
          font-weight: 500;
        }
      }
    }

    &__buttons {
      display: flex;
      flex-direction: row;
      gap: map-get($spacing, md);
    }
  }
}

@media (max-width: map-get($legacyBreakpoints, xl)) {
  .main {
    width: 100% !important;
  }
}

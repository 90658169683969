@import "./../../../assets/scss/variables";
@import "./../../../assets/scss/mixin";

.button {
  @include text(0.875rem, layout-white, bold);
  @include box(darwined-blue-30, 0.3125rem);
  padding: 0.625rem 0.75rem;
  cursor: pointer;
  outline: none;
  &:hover,
  &:active {
    color: map-get($colors, layout-white);
    background: map-get($colors, darwined-blue-50);
  }
  &:disabled {
    color: map-get($colors, layout-grey-30);
    background: map-get($colors, layout-grey-10);
  }
  &.button--white {
    @include text(0.875rem, layout-grey-50);
    display: flex;
    align-items: center;
    justify-content: center;
    background: map-get($colors, foris-white);
    border: 1px solid map-get($colors, layout-grey-50);
    &:hover,
    &:active {
      color: map-get($colors, foris-white);
      background: map-get($colors, layout-grey-50);
    }
    &:disabled {
      @extend .button:disabled;
      border: none;
    }
  }
  &.button--transparent {
    @include text(0.875rem, darwined-blue-50);
    @include box(transparent, 0.3125rem, darwined-blue-50);
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover,
    &:active {
      color: map-get($colors, darwined-blue-50);
      background: transparent;
    }
  }
  &.button--error {
    @include text(0.875rem, foris-white);
    @include box(message-negative-30, 0.3125rem, transparent);
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover,
    &:active {
      color: map-get($colors, message-negative-30);
      @include box(foris-white, 0.3125rem, message-negative-30);
    }
    &:disabled {
      @extend .button:disabled;
      border: none;
      &:hover {
        @extend .button:disabled;
        border: none;
      }
    }
  }
}

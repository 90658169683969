@import "../../../../assets/scss/avocadoVariables";

.usableLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__notUsable {
    color: map-get($colors, layout-grey-30);
  }

  &_status {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: map-get($spacing, xxs);
    font-size: map-get($sizeText, 10);
    line-height: map-get($sizeText, 12);
    font-weight: map-get($fonts, medium);
  }
}

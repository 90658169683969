@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/variables";

.editLabels {
  padding: map-get($spacing, xxl) map-get($spacing, xxxl);

  &_title {
    margin: 0 0 map-get($spacing, xl);
  }

  &_customSelect {
    width: 314px;
  }

  &_actionsButton {
    display: flex;
    gap: map-get($spacing, sm);
    margin: map-get($spacing, xxl) 0 0;
  }
}

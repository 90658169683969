@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/variables";

.creatable {
  min-width: 100%;

  div[class*="-container"] {
    min-width: 30%;
  }
}

.tagsSection {
  margin: 0.5rem 0 0;
  grid-gap: 0.5rem 0.25rem;
}

.cntReference {
  display: grid;
  grid-template-columns: 164px 1fr;
  gap: 1.5rem;
  margin: 0.75rem 0 0;

  &_actions {
    display: flex;
    flex-direction: column;
    text-align: left;

    .actionsTitle {
      @include text(0.875rem, foris-white, medium);
      padding: 0 0 0.5rem 0;
    }

    .actionsButton {
      cursor: pointer;
      @include text(0.75rem, foris-white, medium);
      @include flex(flex-start, center);
      gap: 0.25rem;
      border: none;
      outline: none;
      background-color: transparent;

      &:hover {
        color: map-get($colors, foris-white);
      }

      &_label {
        @include text(0.75rem, foris-white, medium);
      }
    }
  }

  .contentReference {
    margin: 0.5rem 0 0;
    &_tag {
      font-weight: map-get($fonts, extraBold);
      text-transform: uppercase;
    }
    &_error {
      @include text(0.625rem, foris-white);
      padding: 0.25rem 0 0;
    }
    .cntTextArea {
      display: flex;
      min-height: 40px;
      &_field {
        @include box(foris-white, 0.25rem 0 0 0.25rem, layout-grey-30);
        @include text(0.75rem, layout-grey-90);
        border-right: none;
        flex-grow: 1;
        height: 100%;
        min-height: 44px;
        outline: none;
        overflow: hidden;
        padding: 0.5rem 0.625rem;
        resize: none;
        &::placeholder {
          color: map-get($colors, layout-grey-30);
        }
        &:focus {
          border-color: map-get($colors, darwined-blue-30);
          color: map-get($colors, layout-grey-50);
          outline: none;
        }
        &:disabled {
          background: map-get($colors, layout-grey-5);
          border-color: map-get($colors, layout-grey-30);
          &::placeholder {
            color: map-get($colors, layout-grey-30);
          }
        }
      }
    }
  }
}

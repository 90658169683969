@import "../../assets/scss/avocadoVariables";
@import "../../assets/scss/mixin";
@import "../../assets/scss/variables";

.container_advc {
  background: map-get($colors, layout-grey-10);
  height: calc(100vh - 50px);
  overflow-y: scroll;

  .advanceSearchContent {
    background-color: map-get($colors, foris-white);
    box-shadow: map-get($shadow, sm);
    padding: map-get($spacing, lg);

    &_selectorWrapper {
      display: flex;
      flex-direction: column;
      gap: map-get($spacing, sm);
    }

    .hasBottomSeparation {
      padding-bottom: map-get($spacing, md);
      margin-bottom: map-get($spacing, md);
      border-bottom: 1px solid map-get($colors, layout-grey-30);
    }
  }
}

.activePadding {
  padding: 0 0 0 14px;
}

.activePaddingGroup {
  padding: 0 0 14px 14px;
}
.activePaddingEnd {
  padding: 0 0 14px 14px;
  &:last-of-type {
    padding: 0 0 0 14px;
  }
}

.cnt_advance {
  background: map-get($colors, layout-grey-10);
  padding: 22px 24px 80px;
  @include text(16px, layout-grey-90);
  &_title {
    @include text(24px, layout-grey-90);
    align-items: flex-end;
    margin: 0 0 16px;
    padding: 0;
    .back {
      cursor: pointer;
      padding: 0 10px 0 0;
    }
  }
  .advance_item {
    @include box(foris-white, 0);
    display: flex;
    flex-direction: row;
    padding: 0 0 map-get($spacing, md);
    margin: 0 0 map-get($spacing, md);
    border-bottom: 1px solid map-get($colors, layout-grey-30);

    .advance_row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &_title {
      font-size: map-get($sizeText, 14);
      color: #3b4249;
      font-weight: map-get($fonts, medium);
      padding: 0 0 map-get($spacing, md);
    }
    &_select {
      width: 100%;
      max-width: 200px;

      &.advance_item_select__padding {
        padding: 0 0 12px 16px;
      }
      > p {
        @include text(12px, layout-grey-90, medium);
        margin: 0 0 4px;
      }
    }
  }

  .actionButton {
    min-width: 120px;
    font-weight: map-get($fonts, medium);
  }
}

@media (min-width: $md) {
  .cnt_advance {
    padding: 1.375rem 24px 5.375rem;
    &_title {
      padding: 0;
    }
    .advance_item {
      .option_check {
        &.option_check__nivel {
          .option_check_item {
            flex: 1;
            min-width: auto;
          }
        }
        &_item {
          flex: 1;
          min-width: 90px;
          margin: 0 0 16px;
        }
      }
      &_range {
        padding: 0 2rem;
      }
    }
  }
}

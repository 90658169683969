@import "../../../../assets/scss/avocadoVariables";

.bookingModalDetails {
  padding: map-get($spacing, md);

  &_content {
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, md);
    margin: 0 0 map-get($spacing, lg);

    .itemRow {
      display: grid;
      grid-template-columns: 1rem 1fr;
      gap: map-get($spacing, xs);

      &_icon {
        margin: map-get($spacing, xxs) 0 0;
      }

      &_details {
        display: grid;
        gap: map-get($spacing, xs);
        color: map-get($colors, layout-grey-90);
        grid-column-start: 2;

        .detailsWrapper {
          display: flex;
          flex-direction: column;
          text-align: left;

          .iconStatusBooking {
            margin: map-get($spacing, xxs) map-get($spacing, xxs) 0 0;
            display: inline-flex;
          }

          &_title {
            font-size: map-get($sizeText, 10);
            line-height: map-get($sizeText, 12);
            font-weight: map-get($fonts, bold);
          }

          &_value {
            font-size: map-get($sizeText, 14);
            line-height: map-get($sizeText, 16);
            font-weight: map-get($fonts, regular);
          }
        }
      }
    }
  }

  &_footer {
    .footerAction {
      min-width: 120px;
      font-weight: map-get($fonts, medium);
    }
  }
}

@import "../../../assets/scss/mixin";
@import "../../../assets/scss/variables";

.not_results {
  padding: 1rem 1rem 1.5rem 1.5rem;

  section {
    padding: 25px 0 25px 0;
  }
  footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 10px;
    .btn_footer_primary {
      background: map-get($colors, darwined-blue-50);
      border-radius: 5px;
      color: white;
      width: 138px;
      border: none;
      cursor: pointer;
      overflow: hidden;
      padding: 10px;
      &:hover {
        background: map-get($colors, darwined-blue-70);
      }
    }
  }
}

@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixin";

.boxError {
  margin: 0 0 1.5rem;
  &_text {
    @include text(14px, layout-grey-70);
    margin: 0.5rem 0;
  }
  &_link {
    @include text(14px, darwined-blue-30);
    text-decoration: underline;
  }
}

@import "../../../../assets/scss/avocadoVariables";
@import "../../../../assets/scss/mixin";

.sharedCapacity {
  display: flex;
  flex-direction: column;
  gap: map-get($spacing, xxs);
  width: 100%;

  &_grid {
    display: grid;
    grid-template-columns: 96px 10px 96px auto;
    gap: map-get($spacing, xs);
    align-items: center;
  }

  &_separator {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_checkbox {
    padding: 0 0 0 map-get($spacing, md);

    .checkboxField {
      padding: 0;
    }
  }

  &_error {
    font-size: map-get($sizeText, 12);
    color: map-get($colors, message-negative-50);
  }
}

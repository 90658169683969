@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixin";

.detail {
  margin: 1.875rem 0 0;

  .header {
    margin-bottom: 1rem;
    &__title {
      @include text(1rem, layout-grey-90, bold);
    }
    &_subtitle {
      @include text(0.875rem, layout-grey-90, medium);
      padding: 0 0 1rem;
    }
    &_delete {
      width: 24px;
      background: transparent;
      color: map-get($colors, layout-grey-30);
      text-align: center;
      cursor: pointer;
      outline: none;
    }
    &__status {
      @include text(0.875rem, layout-grey-90);
      padding: 0.5rem 0 0;
      .iconStatusBooking {
        margin: 0 0.25rem 0 0;
        &.iconStatusBooking__check {
          color: map-get($colors, message-positive-50);
        }
        &.iconStatusBooking__clock {
          color: map-get($colors, layout-grey-90);
        }
        &.iconStatusBooking__rejected {
          color: map-get($colors, message-negative-70);
        }
      }
    }
  }
  &_radioBtn {
    margin: 0 0 0 0.75rem;
    &:first-of-type {
      margin: 0;
    }
  }
  &_textarea {
    margin: 1.125rem 0 1.5rem;
  }
  &_btn {
    margin: 0 0 0 0.75rem;
    &:first-of-type {
      margin: 0;
    }
  }
  &_text {
    margin: 0 0 0.75rem;
    @include text(0.875rem, layout-grey-90);
  }
}

.iconStatusBooking {
  margin-right: 0.25rem;
  &--check {
    color: map-get($colors, message-positive-50);
  }
  &--clock {
    color: map-get($colors, layout-grey-90);
  }
  &--rejected {
    color: map-get($colors, message-negative-70);
  }
}

.info_container {
  margin-bottom: 1rem;
  .info {
    &__label {
      @include text(10px, layout-grey-90, bold);
      padding: 0 0 0.125rem;
    }

    &__text {
      @include text(0.875rem, layout-grey-90);
    }

    &__link {
      @include text(0.875rem, darwined-blue-50, medium);
      display: flex;
      align-items: center;
      gap: 0.25rem;
      margin-top: 0.25rem;
    }
  }

  .infoBox {
    margin: 1.5rem 0 0.5rem 0;
  }

  &__button {
    margin-right: 1rem;
  }
}

.days {
  gap: 3rem;
}

.resources {
  &__title {
    padding: 1.5rem;
    border-bottom: 1px solid map-get($colors, layout-grey-10);
  }
}

.booking_type_selector {
  margin-right: 1rem;
}

.program_selector {
  margin: 0 !important;
}

.responsable_selector {
  margin: 0 !important;
}

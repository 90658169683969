@import "../../../../assets/scss/avocadoVariables";
@import "../../../../assets/scss/mixin";

.groupComponentInputs {
  margin: map-get($spacing, lg) 0 0;

  &_list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: map-get($spacing, sm);
  }

  .inputLabel {
    &_id {
      color: map-get($colors, layout-grey-50);
      font-size: map-get($sizeText, 10);
      font-weight: map-get($fonts, medium);
      line-height: map-get($sizeText, 12);
    }
  }

  &_tooltip {
    .tooltipLabel {
      line-height: map-get($sizeText, 16);
    }
  }

  &_errors {
    margin: map-get($spacing, lg) 0 0;
    display: grid;
    gap: map-get($spacing, sm);

    .errorWrapper {
      &_component {
        display: block;
        color: map-get($colors, message-negative-70);
        font-size: map-get($sizeText, 14);
        line-height: map-get($sizeText, 16);
        margin: 0 0 map-get($spacing, xs);
        padding: map-get($spacing, xxs) 0 0;
      }

      &_messages {
        display: grid;
        gap: map-get($spacing, xxs);
        list-style: initial;
        padding: 0 0 0 map-get($spacing, md);
        font-size: map-get($sizeText, 14);
        line-height: map-get($sizeText, 16);
        color: map-get($colors, layout-grey-90);
      }
    }
  }
}

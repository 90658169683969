@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/legacyBreakpoints";
@import "../../../../../assets/scss/mixin";

.main {
  @include text(map-get($sizeText, 16), layout-grey-90);
  display: flex;
  margin: 0 8.35%;
  padding: map-get($spacing, lg);
  flex-direction: column;
  justify-content: flex-start;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  &__text {
    @include text(map-get($sizeText, 22), layout-grey-90, light);
    width: 22%;
    min-width: 215px;
    margin: map-get($spacing, md) 0;
  }
}

.download {
  background-color: map-get($colors, foris-white);
  border-radius: map-get($radius, md);
  box-shadow: 0 0 3px 0 rgba(52, 58, 64, 0.3);
  margin: 0.8rem 0;
  padding: map-get($spacing, xs) map-get($spacing, xs) map-get($spacing, xs) 0.2rem;
  &_button {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    gap: map-get($spacing, xs);
    &:hover {
      background-color: map-get($colors, foris-white) !important;
    }
  }
  &_text {
    @include text(map-get($sizeText, 14), darwined-blue-50, medium);
  }
  &_icon {
    color: map-get($colors, darwined-blue-50);
  }
}

@media (max-width: map-get($legacyBreakpoints, xl)) {
  .main {
    margin: 0 !important;
  }
}

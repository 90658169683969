@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixin";

.preferencesContent {
  padding: 1.5rem;
  &_text {
    @include text(14px, layout-grey-70, medium);
    padding: 0 0 1rem;
  }
  &_select {
    min-width: 328px;
    margin: 1rem 0 0;
    &:first-child {
      margin: 0;
    }
  }
}
@media (max-width: map-get($breakpoints, xs)) {
  .preferencesContent {
    padding: 1rem;
    &_select {
      min-width: 100%;
    }
  }
}

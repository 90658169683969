@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/legacyBreakpoints";
@import "../../../../../assets/scss/mixin";

.card {
  width: 83.3%;
  @include box(foris-white, map-get($radius, md), layout-grey-10);
  height: fit-content;
  margin: 0 0 map-get($spacing, xs);
  padding: 1rem 0 0 0;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);

  &_header {
    display: flex;
    justify-content: space-between;
    margin-left: map-get($spacing, md);
    &_title {
      @include text(22px, layout-grey-90, bold);
      display: flex;
      gap: map-get($spacing, md);
      margin: 0;
      padding: 0 0 map-get($spacing, sm);
      &_text {
        margin-left: map-get($spacing, md);
      }
      &_link {
        @include text(map-get($sizeText, 14), darwined-blue-30, bold);
        &:hover {
          @include text(map-get($sizeText, 14), darwined-blue-30, bold);
        }
      }
    }
    &_info {
      align-items: flex-end;
      min-width: 250px;
    }
  }

  &_section {
    margin: 0 map-get($spacing, md);
    padding: map-get($spacing, lg) 0 map-get($spacing, lg);
    &:nth-last-child(2) {
      border-bottom: none;
    }
    &:last-child {
      border-bottom: none;
    }
  }

  .detail_container {
    display: flex;
    row-gap: 1rem;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
  }
  .detail_row {
    display: flex;
    gap: 3.3%;
  }
  .detail_item {
    display: flex;
    flex-direction: column;
    &_title {
      @include text(map-get($sizeText, 12), layout-grey-90, bold);
    }
    &_value {
      @include text(map-get($sizeText, 14), layout-grey-90, light);
      margin-top: 0.4rem;
    }
    &_list {
      display: grid;
      gap: map-get($spacing, xxs);
      margin: map-get($spacing, xxs) 0 0 0;
      max-height: 70px;
      overflow: auto;

      .itemLink {
        @include text(map-get($sizeText, 12), darwined-blue-50, medium);
        cursor: pointer;
      }
    }

    &__link {
      @include text(map-get($sizeText, 14), darwined-blue-30, light);
      margin-top: 0.4rem;
      cursor: pointer;
    }

    // detail columns
    &_crn {
      min-width: 10px;
    }
    &_campus {
      min-width: 10px;
    }
    &_course {
      min-width: 30px;
    }
    &_department {
      min-width: 10px;
    }
    &_school {
      min-width: 10px;
    }
    &_state {
      min-width: 5px;
    }
    &_cupos {
      min-width: 5px;
      &_value_container {
        display: flex;
        align-items: center;
      }

      &_info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 23.59px;
        width: 1.2rem;

        &_icon {
          position: relative;
          bottom: 23px;
          margin-top: 0.3rem;
          margin-left: 0.2rem;
          color: map-get($colors, layout-grey-90);
        }

        &_tooltip {
          visibility: hidden;
          position: relative;
          bottom: 23px;
          right: 55px;
          width: 8.65rem;
          background-color: map-get($colors, layout-grey-90);
          color: map-get($colors, foris-white);
          text-align: center;
          padding: map-get($spacing, xs) 0.8rem;
          border-radius: 2px;
          box-shadow: 0 0 3px 0 rgba(52, 58, 64, 0.3);
          &::after {
            /* Small bottom arrow */
            content: " ";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: map-get($colors, layout-grey-90) transparent transparent transparent;
          }
        }

        &:hover p {
          visibility: visible;
        }
      }

      &_error {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 23.59px;
        width: 1.2rem;

        &_icon {
          position: relative;
          bottom: 23px;
          margin-top: 0.3rem;
          margin-left: 0.2rem;
          color: map-get($colors, message-negative-30);
        }

        &_tooltip {
          visibility: hidden;
          position: relative;
          bottom: 23px;
          right: 55px;
          width: 8.65rem;
          background-color: map-get($colors, layout-grey-90);
          color: map-get($colors, foris-white);
          text-align: center;
          padding: map-get($spacing, xs) 0.8rem;
          border-radius: 2px;
          box-shadow: 0 0 3px 0 rgba(52, 58, 64, 0.3);
          &::after {
            /* Small bottom arrow */
            content: " ";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: map-get($colors, layout-grey-90) transparent transparent transparent;
          }
        }

        &:hover p {
          visibility: visible;
        }
      }
    }
    &_donut {
      min-width: 20px;
      padding: 0 !important;
      &_dataCircle {
        margin: 0 map-get($spacing, sm) 0 0;
      }
      &_dataLabels {
        width: 48px;
      }
    }
    &_efficiency {
      min-width: 10px;
    }
    &_capacity {
      min-width: 10px;
    }
    &_visibility {
      min-width: 10px;
    }
  }

  .schedules_container {
    display: flex;
    row-gap: map-get($spacing, xs);
    flex-direction: column;
    row-gap: map-get($spacing, md);
  }

  .schedule_item {
    width: 25%;
    &_title {
      @include text(map-get($sizeText, 12), layout-grey-90, bold);
    }
    &_value {
      @include text(map-get($sizeText, 14), layout-grey-90, light);
      margin-top: 0.4rem;
    }
  }

  .instructors {
    max-height: 125px; // ~5 instructors high
    overflow-y: auto;

    .instructor {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      gap: map-get($spacing, md);
      height: 28px;

      &_name {
        @include text(map-get($sizeText, 14), layout-grey-90);
      }

      &_mainLabel {
        @include text(map-get($sizeText, 10), layout-grey-70, medium);
        background-color: map-get($colors, layout-grey-10);
        padding: map-get($spacing, xxs) map-get($spacing, xs);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: map-get($radius, md);
        height: 20px;
      }
    }
  }

  .group_info {
    @include text(map-get($sizeText, 12), layout-grey-90, light);
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }
}

.button_container {
  display: flex;
  justify-content: center;
  padding: 0 !important;
  margin: 0;
  border-top-left-radius: none;
  border-top-right-radius: none;
}

.button {
  padding: map-get($spacing, md) 0;
  border: none !important;
  width: 100%;
  height: 100%;
  &:hover {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.hide {
  display: none !important;
}

.topSeparator {
  border-top: 1px solid map-get($colors, layout-grey-10);
}
.bottomSeparator {
  border-bottom: 1px solid map-get($colors, layout-grey-10);
}

@media (max-width: map-get($legacyBreakpoints, xl)) {
  .card {
    width: 100% !important;
  }
}

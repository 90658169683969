@import "../../../assets/scss/mixin";
@import "../../../assets/scss/avocadoVariables";

.switchLocale {
  width: 100%;

  &_btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
    width: 100%;

    .btn_label.btn_label {
      color: map-get($colors, layout-grey-50);
      font-size: map-get($sizeText, 14);
      font-weight: map-get($fonts, regular);
    }
  }

  &_dropdown {
    background: var(--color-neutral-00);
    border-radius: map-get($radius, lg);
    box-shadow: var(--shadow-lg);
    overflow: hidden;
    padding: map-get($spacing, xs);
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, xs);
    width: 100%;
    margin: map-get($spacing, md) 0 0;

    .item {
      background-color: transparent;
      border-radius: map-get($radius, md);
      border: map-get($borders, md) solid transparent;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      padding: map-get($spacing, xs);

      .item_label {
        color: var(--color-neutral-70);
      }

      &.item__active {
        background-color: var(--color-primary-10);
        border-color: var(--color-primary-30);
      }

      &:hover {
        border-color: var(--color-primary-30);
        background-color: var(--color-primary-10);
      }
    }
  }
}

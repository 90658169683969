@import "../../assets/scss/avocadoVariables";
@import "../../assets/scss/mixin";

.content {
  flex-direction: column;
  position: relative;
  top: 0px;
  height: calc(100vh - 50px);
  padding: 8rem 0 0;
  background: map-get($colors, darwined-blue-50);
  color: map-get($colors, foris-white);
  overflow-y: hidden;
  &_img {
    width: 460px;
    margin: 0 0 2.5rem;
    .img {
      margin: 0;
      padding: 0;
    }
  }

  .context {
    width: 610px;
    .advanceSearch {
      @include flex(space-between, center);
      margin: 0 auto;

      &_search {
        width: 100%;
      }

      &_link {
        @include text(map-get($sizeText, 14), layout-grey-10);
        width: 170px;
        margin: 0 0 0 map-get($spacing, lg);
      }
    }
  }
}

@import "../../../assets/scss/mixin";
@import "../../../assets/scss/variables";

.component_check {
  display: flex;
  align-items: flex-end;
  padding: 0.75rem 0 0;
  &.component_check--disabled {
    .cnt_option {
      &-label {
        color: map-get($colors, layout-grey-30);
      }
    }
  }
  .cnt_option {
    @include text(0.875rem, layout-grey-90);
    display: block;
    position: relative;
    padding: 0 0 0 1.625rem;
    cursor: pointer;
    user-select: none;
    &:hover {
      .cnt_option-input ~ .cnt_option-check {
        background: rgba(map-get($colors, layout-grey-10), 0.1);
      }
      .cnt_option-input:checked ~ .cnt_option-check {
        background: map-get($colors, darwined-blue-30);
      }
      .cnt_option-input:disabled ~ .cnt_option-check {
        background: map-get($colors, layout-grey-30);
      }
    }
    &-label {
      @include text(0.875rem, layout-grey-90);
    }
    &-input {
      width: 0;
      height: 0;
      opacity: 0;
      cursor: pointer;
      &:checked ~ .cnt_option-check {
        background: map-get($colors, darwined-blue-50);
        border: 1px solid map-get($colors, darwined-blue-50);
      }
      &:checked ~ .cnt_option-check:after {
        display: block;
      }
      &:disabled ~ .cnt_option-check {
        background: map-get($colors, layout-grey-10);
        border: 1px solid map-get($colors, layout-grey-30);
        border-width: 0;
        cursor: no-drop;
      }
      &:checked:disabled ~ .cnt_option-check::after {
        cursor: no-drop;
        &:hover {
          background: rgba(map-get($colors, layout-grey-10), 0.1);
        }
      }
    }
    &-check {
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      background: map-get($colors, foris-white);
      border: 1px solid map-get($colors, layout-grey-30);
      border-radius: 0.125rem;
      &::after {
        content: "";
        position: absolute;
        display: none;
        left: 6px;
        top: 2px;
        width: 4px;
        height: 10px;
        border: solid map-get($colors, foris-white);
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
}

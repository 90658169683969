@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixin";

.section_info {
  padding: 1.5rem;
  .sectionRow {
    @include text(0.875rem, layout-grey-90);
    &_title {
      @include text(0.625rem, layout-grey-90, bold);
    }
    &_link {
      @include text(0.875rem, darwined-blue-30);
      cursor: pointer;

      &:not(:last-child) {
        margin: 0 0 0.125rem 0;
      }
    }
    &_item {
      padding: 0 0 0.75rem;
      &:last-child {
        padding: 0;
      }
    }
    &_column {
      padding: 0 25px 0 0;
      &:last-child {
        padding: 0;
      }

      .dataItems {
        &:not(:last-child) {
          margin: 0 0 0.125rem 0;
        }
      }
    }
    &_select {
      position: relative;
      width: 50%;
      z-index: 10;
    }
    // Fix error :(
    .section_error {
      @include text(0.75rem, foris-white);
      margin: 0.75rem 0;
      width: 100%;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
      border-radius: 0.25rem;
      border: none;
      background-color: map-get($colors, message-negative-30);
      .list_error {
        padding: 0.75rem;
        span {
          @include text(0.875rem, foris-white, bold);
        }
        ul:nth-of-type(1) {
          list-style-position: inside;
          padding: 0;
          margin: 0;
        }
      }
    }
  }
  .icon {
    padding: 12px 10px 0 0;
  }
  .sectionFooter {
    padding: 1.5rem 0 0;
    &_btn {
      margin: 0 0 0 1.5rem;
      &:first-child {
        margin: 0;
      }
    }
  }

  .absent_resource {
    &__required {
      color: map-get($colors, message-negative-30);
    }
    &__not_required {
      color: map-get($colors, layout-grey-40);
    }
  }
}

@import "../../../../assets/scss/avocadoVariables";
@import "../../../../assets/scss/mixin";

.advancedLabelsSelectors {
  display: flex;
  flex-direction: column;
  gap: map-get($spacing, md);
  margin: map-get($spacing, md) 0;

  &_header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: map-get($spacing, sm);

    .headerTitle {
      flex-shrink: 0;
      font-size: map-get($sizeText, 14);
      font-weight: map-get($fonts, medium);
      color: map-get($colors, layout-grey-70);
    }

    .headerLine {
      width: 100%;
      height: 1px;
      background-color: map-get($colors, layout-grey-10);
    }
  }

  &_control {
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, sm);
    width: 100%;
    max-width: 280px;

    .labelsSelector {
      width: 100%;
    }
  }

  &_labels {
    display: flex;
    flex-wrap: wrap;
    gap: map-get($spacing, xs);
    border-top: 1px solid map-get($colors, layout-grey-30);
    padding: map-get($spacing, md) 0 0 0;
  }
}

@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/variables";

.repeatActions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: map-get($spacing, xs);
  font-size: map-get($sizeText, 14);
  color: map-get($colors, layout-grey-90);
  margin: map-get($spacing, sm) 0 0 0;

  &_actionButton {
    font-weight: map-get($fonts, medium);
  }
}

.repeat {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;

  &_radio {
    width: auto !important;
    padding: 0 0 0 1.5rem;
    &:first-of-type {
      padding: 0;
    }
  }
  .item {
    &_text {
      @include text(12px, layout-grey-70);
      .bold {
        @include text(12px, layout-grey-90, medium);
      }
    }
    &_table {
      padding: 0.75rem 0 0;
    }
    .errorBox {
      margin: 0.75rem 0 0;
      &_text {
        @include text(0.75rem, layout-grey-90);
      }
    }
  }

  &__weekly {
    display: flex;
    align-items: center;
    gap: 1rem;
    background: map-get($colors, layout-grey-5);
    border: 1px solid map-get($colors, layout-grey-10);
    padding: 0.534rem 0;
    flex-direction: column;
    border-radius: 4px;
    &__container {
      margin-top: 1rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
    }
    &__label {
      @include text(20px, layout-grey-70, bold);
    }
    &__weeks {
      margin-top: 1rem;
    }
  }
}

.warning {
  width: 100%;
  margin-top: 0.75rem;
  &_text {
    @include text(0.75rem, layout-grey-90);
  }
}

@import "../../../../assets/scss/avocadoVariables";
@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/variables";

.selectors {
  width: 100%;
  max-width: 220px;
  align-self: flex-start;
  > p {
    @include text(0.75rem, layout-grey-90, medium);
    margin: 0 0 0.25rem;
  }

  &_labelItem {
    @include text(0.75rem, layout-grey-90, bold);
    margin: 0 0 map-get($spacing, xxs);
    display: block;
  }
}
.cntOptions {
  padding: map-get($spacing, xxs) 0;

  &_title {
    @include text(0.875rem, layout-grey-70, light);
    white-space: normal;

    .bold {
      font-weight: map-get($fonts, medium);
    }
  }
  .detailOption {
    &_item {
      @include text(0.625rem, layout-grey-50, light);
      text-transform: uppercase;
      white-space: normal;

      &:last-of-type {
        .bold {
          &::after {
            content: "";
            padding: 0;
          }
        }
      }
      .bold {
        font-weight: map-get($fonts, medium);
        padding: 0 0 0 0.125rem;
        &::after {
          content: "|";
          padding: 0 0.25rem;
        }
      }
    }
  }
}

@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/legacyBreakpoints";
@import "../../../../../assets/scss/mixin";

.state {
  display: flex;
  gap: 0.3rem;
  align-items: center;
  justify-content: space-between;
  max-width: 70px;
}

.table {
  margin: 0.875rem 0 0 0;
  width: 100%;
}

.stateColumn {
  &_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.3rem;
  }

  &_icon {
    &__clock {
      color: map-get($colors, message-alert-50);
    }
    &__error {
      color: map-get($colors, message-negative-50);
    }
    &__check {
      color: map-get($colors, message-positive-50);
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leftHeader {
  width: 33.3%;
  @include text(map-get($sizeText, 16), layout-grey-90, medium);
  &_lastUpdate {
    color: map-get($colors, layout-grey-70);
  }
}

.middleHeader {
  width: 33.3%;
  display: flex;
  justify-content: center;
}

.rightHeader {
  width: 33.3%;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: map-get($legacyBreakpoints, xl)) {
  .table,
  .noResult {
    width: 100% !important;
  }
}

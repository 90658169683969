@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/variables";

.compare {
  &_icon {
    color: map-get($colors, darwined-blue-30);
    align-self: center;
    margin: 0 1.5rem;
  }
}

.redTitleCard {
  h2 {
    color: map-get($colors, message-negative-30);
  }
}

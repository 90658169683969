//Cantidad de grillas
$grid-cols: 12;
$i: 0;

@mixin text($size, $color, $weight: regular) {
  font-size: $size;
  color: map-get($colors, $color);
  font-weight: map-get($fonts, #{$weight});
}

@mixin box($bg, $radius, $border: none) {
  @if ($bg == transparent) {
    background: $bg;
  } @else {
    background: map-get($colors, $bg);
  }
  @if ($border == "none") {
    border: $border;
  } @else if ($border != "none") {
    background: map-get($colors, $bg);
    border: 1px solid map-get($colors, $border);
  }
  border-radius: $radius;
}

@mixin flex($justify, $align: flex-start) {
  display: flex;
  justify-content: $justify;
  align-items: #{$align};
}

@mixin colWidth($col) {
  $width: $grid-cols/$col;
  @for $i from 0 through $grid-cols {
    @if $i == $grid-cols {
      flex-basis: 100%;
    } @else if $i != $grid-cols and $i != 0 {
      flex-basis: (100 / $width) * 1%;
      max-width: (100 / $width) * 1%;
    }
  }
}

@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/variables";

.content {
  position: relative;
  background: map-get($colors, layout-white);
  overflow-x: hidden;
  max-width: 100vw;
  display: flex;
  @for $i from 100 through 200 {
    &.content__height#{$i} {
      $height: $i * 1px;
      min-height: calc(100vh - #{$height} - 50px);
    }
  }
  .aside {
    background: map-get($colors, foris-white);
    padding: 1.5rem 0 2rem;
    min-width: 240px;
    box-shadow: inset -1px 0 4px 0 rgba(0, 0, 0, 0.25);
  }
  .results {
    min-width: calc(100% - 240px);
    background: map-get($colors, layout-white);
    padding: 1.5rem 2rem 1.5rem 1.5rem;
    &_content {
      padding: 1.5rem 0 0;
    }
  }
}

@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/variables";

.cntSearch {
  &.cntSearch__advance {
    padding: 2rem 2.5rem;
  }
  &_title {
    @include text(1.25rem, layout-grey-90, bold);
    margin: 0;
    padding: 0 0 1.5rem;
  }
  .item {
    padding: 0 0 1rem;
    &:first-of-type {
      margin: 0 1rem 0 0;
    }
    &.item__margin {
      margin: 0 1rem 0 0;
    }
    &_label {
      @include text(0.75rem, layout-grey-90, bold);
      padding: 0 0 0.25rem;
    }
    &_select {
      width: 130px;
      margin: 0 0 0 1rem;
      &:first-of-type {
        margin: 0;
      }
    }
    &_table {
      max-width: 415px;
      padding: 0.5rem 0 0;
      .btnTrash {
        width: 50px;
        padding: 0 0.5rem;
        text-align: center;
        border: none;
        background: transparent;
        outline: none;
        cursor: pointer;
        &_icon {
          color: map-get($colors, darwined-blue-30);
        }
      }
    }
    &_link {
      @include text(0.875rem, darwined-blue-30);
      padding: 0 0 0 1rem;
      cursor: pointer;
    }
    &_input {
      width: 50px;
      margin: 0;
    }
    &_check {
      margin: 0 0 0 1rem;
    }
    &_text {
      @include text(2rem, layout-grey-30);
      padding: 0 0.75rem;
    }
  }

  .cntBtn {
    padding: 2rem 0 3rem;
    &_item {
      margin: 0 0 0 0.5rem;
      &:first-of-type {
        margin: 0;
      }
    }
  }
}

.searchSelect {
  margin: 0 0 16px;
  &:last-of-type {
    margin: 0;
  }
  &_title {
    @include text(12px, layout-grey-50, medium);
    margin: 0;
    padding: 0 0 4px;
  }
  .activePadding {
    padding: 0 0 0 16px;
    max-width: 240px;
    &:first-child {
      padding: 0;
    }
  }
  .inputBlocks {
    &:first-child {
      margin: 0;
    }
    padding: 0;
  }
}

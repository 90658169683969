@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/variables";

.cntList {
  min-height: calc(100vh - 50px);
  padding: 1.75rem 2rem;
  background: map-get($colors, layout-white);
  &_title {
    @include text(1.5rem, layout-grey-90);
    padding: 0 0 1.5rem;
  }
  .detaialList {
    .header {
      flex-wrap: nowrap;
      padding: 3.125rem 0 0.875rem;
      border-bottom: 1px solid map-get($colors, layout-grey-30);
      &_count {
        @include text(1rem, layout-grey-90, bold);
      }
      &_text {
        @include text(1rem, layout-grey-90);
        margin: 0 0.75rem 0 0;
      }
      &_select {
        min-width: 170px;
      }
    }
    .cntTable {
      padding: 0.875rem 0 0;
      &_input {
        margin: 0 0 0.5rem;
      }
    }
  }
  .iconCheck {
    color: map-get($colors, message-positive-50);
  }
  .iconClose {
    color: map-get($colors, message-negative-50);
  }
  .paginator {
    padding: 0.5rem 0 0.5rem 0;
  }
  .linkDetail {
    @include text(0.875rem, darwined-blue-30);
    cursor: pointer;
  }
}

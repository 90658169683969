@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixin";

.cntBookingDetail {
  padding: 1.75rem 1rem;
  &_link {
    @include flex(flex-start, center);
    @include text(24px, layout-grey-90);
    padding: 0 0 1rem;
    .iconBack {
      margin: 0 0.625rem 0 0;
    }
  }

  .cardDetail {
    margin: 1.875rem 0 0;
    .headerDetail {
      margin: 0 0 1rem;
      &_title {
        @include text(1rem, layout-grey-90, bold);
      }
      &_subtitle {
        @include text(0.875rem, layout-grey-90, medium);
        padding: 0 0 1rem;
      }
      &_delete {
        width: 24px;
        background: transparent;
        color: map-get($colors, layout-grey-30);
        text-align: center;
        cursor: pointer;
        outline: none;
      }
      &_status {
        @include text(0.875rem, layout-grey-90);
        padding: 0.5rem 0 0;
        .iconStatusBooking {
          margin: 0 0.25rem 0 0;
          &.iconStatusBooking__check {
            color: map-get($colors, message-positive-50);
          }
          &.iconStatusBooking__clock {
            color: map-get($colors, layout-grey-90);
          }
          &.iconStatusBooking__rejected {
            color: map-get($colors, message-negative-70);
          }
        }
      }
    }
    .cntInfo {
      &_item {
        padding: 0.75rem 0 0;
        &:first-child {
          padding: 0;
        }
        .label {
          @include text(10, layout-grey-90, bold);
          padding: 0 0 0.125rem;
        }
        .info {
          @include text(0.875rem, layout-grey-90);
        }
      }
    }
    &_radioBtn {
      margin: 0 0 0 0.75rem;
      &:first-of-type {
        margin: 0;
      }
    }
    &_textarea {
      margin: 1.125rem 0 1.5rem;
    }
    &_btn {
      margin: 0 0 0 0.75rem;
      &:first-of-type {
        margin: 0;
      }
    }
    &_text {
      margin: 0 0 0.75rem;
      @include text(0.875rem, layout-grey-90);
    }
  }
}

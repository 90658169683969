@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixin";

.cardRequest {
  margin: 0 0 1.5rem;
  &_text {
    @include text(14px, layout-grey-90, medium);
    padding: 0 0 1rem;
  }
  &_classroomName {
    @include text(20px, darwined-blue-30, bold);
    padding: 0 0 0.625rem;
    text-transform: uppercase;
  }
  &_item {
    min-width: 328px;
    margin: 1rem 0 0;
    &:first-child {
      margin: 0;
    }
  }
}

@media (max-width: map-get($breakpoints, md)) {
  .cardRequest {
    &_item {
      min-width: 260px;
      max-width: 260px;
      &:last-of-type {
        max-width: none;
      }
    }
  }
}

@import "../../../assets/scss/mixin";
@import "../../../assets/scss/avocadoVariables";

.switchTheme {
  &_label {
    @include flex(center, center);
    position: relative;

    .button {
      background-color: var(--color-neutral-30);
      border-radius: 0.625rem;
      width: 36px;
      height: 20px;
      position: relative;
      cursor: pointer;
      padding: 0.125rem;

      &_iconWrapper {
        background-color: var(--color-neutral-00);
        border-radius: 50%;
        height: 16px;
        width: 16px;
        left: 2px;
        top: 2px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease;

        svg {
          height: 12px;
          width: 12px;
        }
      }
    }

    .switch[type="checkbox"] {
      opacity: 0;
      width: 0;
      height: 0;
      &:checked + .button .button_iconWrapper {
        left: calc(100% - 2px);
        transform: translateX(-100%);
      }
    }
  }

  &.switchTheme__darkMode {
    .switchTheme_label {
      .button {
        background-color: var(--color-neutral-70);
      }
    }
  }
}

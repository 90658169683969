@import "../../../../assets/scss/avocadoVariables";
@import "../../../../assets/scss/mixin";

.packagesAndGroupsReport {
  padding: map-get($spacing, lg);

  &_content {
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, md);
    padding: map-get($spacing, md);
    border-radius: map-get($radius, md);
    box-shadow: map-get($shadow, sm);
    background-color: map-get($colors, foris-white);
  }

  &_actions {
    margin: map-get($spacing, lg) 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: map-get($spacing, sm);

    .actionButton {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: map-get($spacing, xxs);
      font-weight: map-get($fonts, medium);
    }
  }
}

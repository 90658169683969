@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/legacyBreakpoints";
@import "../../../../../assets/scss/mixin";

.bottom {
  margin-top: map-get($spacing, lg);
  width: 83.3%;
  display: flex;
  flex-direction: column;
  gap: map-get($spacing, xs);

  &_label {
    @include text(map-get($sizeText, 16), layout-black, bold);
  }
  &_text {
    @include text(map-get($sizeText, 14), layout-black);
  }

  &_buttons {
    display: flex;
    justify-content: flex-start;
    gap: map-get($spacing, md);
  }
}

@media (max-width: map-get($legacyBreakpoints, xl)) {
  .bottom {
    width: 100% !important;
  }
}

@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/legacyBreakpoints";
@import "../../../../../assets/scss/mixin";

.filtersCard {
  width: 83.3%;
  min-width: 1244px;

  &_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_left {
      display: flex;
      justify-content: flex-start;
      flex-grow: 3;
      gap: map-get($spacing, md);
    }
    &_right {
      @include text(map-get($sizeText, 12), layout-grey-70);
      flex-grow: 7;
      text-align: end;
    }
    &_text {
      @include text(map-get($sizeText, 16), layout-grey-90, bold);
      margin: 0;
      flex-grow: 2;
      min-width: 108px;
    }
    &_switch {
      @include text(map-get($sizeText, 16), layout-grey-90);
      flex-grow: 1;
    }
  }

  @include box(foris-white, 0.25rem, layout-grey-10);
  height: fit-content;
  margin: 0 0 map-get($spacing, xs);
  padding: map-get($spacing, md);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);

  &_typeSelector {
    @include text(map-get($sizeText, 14), layout-black);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: map-get($spacing, md);
    gap: map-get($spacing, xs);
    &_text {
      width: 7%;
    }
    &_switch {
      width: auto !important;
    }
  }

  .filters {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: map-get($spacing, md);
    gap: map-get($spacing, sm);

    .filter_pair_container {
      background-color: map-get($colors, layout-light);
      border-radius: map-get($radius, md);
      border: 1px solid map-get($colors, layout-grey-10);
      width: 50%;
      .filter_pair {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: map-get($spacing, xs);
        &__mark {
          display: block;
          position: absolute;
          right: 11.5rem;
        }
      }

      .filter {
        width: 48%;
      }
    }

    .filter {
      &_title {
        font-size: map-get($sizeText, 14);
        margin-bottom: map-get($spacing, xs);
      }
      width: 48%;
      &_single {
        width: 20%;
      }
    }
  }

  .advancedFilters {
    margin: map-get($spacing, md);
    display: flex;
    flex-direction: column;

    &_separator {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      &_button {
        padding-left: 0;
        padding-right: 0;
        border: none !important;
        align-items: center;
        justify-content: flex-start;
        gap: map-get($spacing, xs);
        width: 14rem;
        &:hover {
          background-color: transparent;
        }
        &:active {
          background-color: transparent;
        }
        p {
          @include text(map-get($sizeText, 14), darwined-blue-30, bold);
          margin-left: 0.3rem;
        }
        &_label {
          display: flex;
        }
        &_number {
          width: 1rem;
          margin-left: 0.3rem;
        }
      }
      &_line {
        border: 1px solid map-get($colors, layout-grey-10);
        border-radius: 5px;
        width: 82%;
      }
    }

    &_container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: map-get($spacing, xl) 4rem;
      padding: map-get($spacing, xs) map-get($spacing, md) map-get($spacing, md);
    }

    .advancedFilter {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
      &_title {
        @include text(map-get($sizeText, 14), layout-black, bold);
      }
      &_checkbox {
        @include text(map-get($sizeText, 14), layout-black);
      }
      &_selector {
        width: 24%;
        min-width: 170px;
      }
    }

    &_bottomLine {
      border: 1px solid map-get($colors, layout-grey-10);
      border-radius: 5px;
      width: 100%;
    }

    &_tags_container {
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 0.7rem;
    }
    &_tags {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-start;
      gap: 0.3rem;

      &_button {
        border: none !important;
        display: flex;
        align-items: center;
        min-width: 10rem;
        &:hover {
          background-color: transparent;
        }
        &:active {
          background-color: transparent;
        }
        p {
          @include text(map-get($sizeText, 14), darwined-blue-30, bold);
          margin-left: 0.3rem;
        }
      }
    }
  }

  .buttons_section {
    width: 60%;
    padding: map-get($spacing, md) 0 0 map-get($spacing, md);
    display: flex;

    &__button {
      height: 36px;
      margin-right: map-get($spacing, md);
      &_disabled {
        cursor: default;
        color: map-get($colors, layout-grey-40);
      }
      &:first-child {
        margin-right: map-get($spacing, md);
      }
    }

    .nonBordered {
      border: none;
    }
  }
}

.hide {
  display: none !important;
}

@media (max-width: map-get($legacyBreakpoints, xl)) {
  .filtersCard {
    width: 100% !important;
    min-width: unset;
    .filters {
      .filter_pair_container {
        .filter_pair {
          &__mark {
            right: 3.3rem;
          }
        }
      }
    }
  }
}

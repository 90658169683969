@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/mixin";

.dataGrid {
  ul {
    overflow: visible !important;
  }
}

.table {
  .leftHeader {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 40%;
    &_title {
      @include text(map-get($sizeText, 16), layout-grey-90, medium);
    }
    &_button {
      background-color: transparent;
    }
  }
}
